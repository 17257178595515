import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataBaseCompanyItemType, DataType } from "../type";
import debounce from "lodash/debounce";
import React from "react";
import { CommonRoleWrap, FleetToast } from "@/common";
import { ColumnsType } from "antd/es/table";
import { deleteCompanyAction, getCompanyGroupAction } from "@/action/dataBase/company";
import { Popover } from "@/_components";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const cacheSource = useRef<{ [index: number]: DataBaseCompanyItemType[] }>({});
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const record = useRef<DataBaseCompanyItemType>(null);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [hide, setHide] = useState(true);
	const [editType, setEditType] = useState<"add" | "edit">("add");

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
		refresh: boolean;
	}>({ total: 0, pageNum: 1, pageSize: 20, refresh: false });

	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager.pageNum, pager.total, pager.refresh]);

	const handleEdit = (type: "add" | "edit", item: DataBaseCompanyItemType | null) => {
		record.current = item;
		setEditType(type);
		setHide(false);
	};

	const opereteColumns: ColumnsType<DataType> = useMemo(() => {
		return [
			{
				dataIndex: "option",
				width: 120,
				title: "Action",
				key: "option",
				align: "center",
				fixed: "right",
				render: (value, record, index) => {
					return (
						<div className="td-operate">
							<CommonRoleWrap roleStr="company:list:edit">
								<span onClick={() => handleEdit("edit", record)}>Edit</span>
							</CommonRoleWrap>
							<CommonRoleWrap roleStr="company:list:delete">
								<Popover
									render={(setIsOpen) => {
										return (
											<FleetToast
												label="Are you sure delete this item?"
												onCancel={() => {
													setIsOpen(false);
												}}
												onConfirm={() => {
													setIsOpen(false);
													deleteCompany("single", record);
												}}
											/>
										);
									}}
								>
									<span className="font_family fleet-delete" style={{ color: "red" }}></span>
								</Popover>
							</CommonRoleWrap>
						</div>
					);
				}
			}
		];
	}, [record]);

	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const getCompanyGroupFront = () => {
		setLoading(true);
	};

	const getCompanyGroupSuccess = (response) => {
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.items;
				total = response?.data?.totalCount;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({ ...prev, total, refresh: !prev?.refresh, pageNum: 1 }));
		setLoading(false);
	};

	const getCompanyGroupFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getCompanyGroup = (searchVal?: string) => {
		getCompanyGroupAction(
			{
				pageNum: 1,
				pageSize: pager.pageSize,
				keyword: searchVal ?? search
			},
			getCompanyGroupFront,
			getCompanyGroupSuccess,
			getCompanyGroupFailed,
			dispatch,
			navigate
		);
	};

	const deleteCompanySuccess = (response) => {
		reminder("success", response?.msg);
		getCompanyGroup();
	};

	const deleteCompany = (type: "single" | "all", record?: DataBaseCompanyItemType) => {
		if (type === "all" && selectedRowKeys?.length === 0) return;
		deleteCompanyAction(
			type === "single" ? [record?.id] : selectedRowKeys,
			getCompanyGroupFront,
			deleteCompanySuccess,
			getCompanyGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleReset = () => {
		setHide(true);
		setSelectedRowKeys([]);
		getCompanyGroup();
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		getCompanyGroupAction(
			{
				pageNum: 1,
				pageSize: pager.pageSize,
				keyword: search
			},
			getCompanyGroupFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.items;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getCompanyGroupFailed,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			cacheSource.current = {};
			setSearch(val);
			getCompanyGroup(val);
		};
		return debounce(loadOptions, 500);
	}, []);

	useEffect(() => {
		getCompanyGroup();
	}, []);

	return {
		hide,
		search,
		record,
		selectedRowKeys,
		pager,
		dataSource,
		loading,
		onSearch,
		opereteColumns,
		editType,
		setHide,
		onPageChange,
		onSelectChange,
		handleReset,
		deleteCompany,
		handleEdit
	};
};

export default useIndex;
