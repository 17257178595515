import React, { FC, useState } from "react";
import style from "./index.module.less";
import { RouteLegFilterProps } from "./type";
import { FleetInput } from "@/components";
import { Button, Icon, Popover } from "@/_components";
import { statuOptions } from "./source";
import { FleetCustomRadio } from "@/components/Radio";
import { RouteLegItemType } from "../item/module";
import { useLocation } from "react-router-dom";

const RouteLegFilter: FC<RouteLegFilterProps> = ({
	onSearch,
	onConfirm,
	selectStatuItems,
	total
}) => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [selectRows, setSelectRows] = useState<RouteLegItemType["status"][]>([
		"Preparation",
		"Departed",
		"Arrived",
		"Completed"
	]);

	const handleRowSelect = (key: RouteLegItemType["status"]) => {
		setSelectRows((prev) => {
			const index = prev?.findIndex((item) => key === item);
			if (index === -1) {
				prev?.push(key);
			} else {
				prev?.splice(index, 1);
			}
			return [...prev];
		});
	};

	return (
		<div className={style["route-legFilter"]}>
			<span className="legFilter-label">Total Vessel: ({total ?? 0})</span>
			<Popover
				trigger="click"
				placement="bottom-end"
				render={(setIsOpen) => {
					return (
						<div className={style["legFilter-statuOptions"]}>
							<ul>
								{statuOptions?.map((item, index) => {
									return (
										<FleetCustomRadio
											key={index}
											value={selectRows?.includes(item?.value)}
											onChange={(checked: boolean) => {
												handleRowSelect?.(item?.value);
											}}
											label={item?.label}
										/>
									);
								})}
							</ul>
							<div className="statuOptions-footer">
								<Button
									size="minuscule"
									type="secondary"
									onClick={() => {
										setSelectRows(selectStatuItems);
										setIsOpen(false);
									}}
								>
									Reset
								</Button>
								<Button
									size="minuscule"
									type="primary"
									onClick={() => {
										console.log("selectRows", selectRows);
										onConfirm?.(selectRows);
										setIsOpen(false);
									}}
								>
									Ok
								</Button>
							</div>
						</div>
					);
				}}
				children={
					<div className="legFilter-container">
						<Icon type="fleet-filter1" className="legFilter-icon" />
						<span>Filter ({selectRows?.length})</span>
					</div>
				}
			/>
		</div>
	);
};

export default RouteLegFilter;
