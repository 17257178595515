import React, { FC, useRef } from "react";
import { RouteCommonProvider } from "./store";
import { useRouteCommonIndex } from "./hooks";
import style from "./index.module.less";
import classnames from "classnames";
import {
	RouteCommonAisLine,
	RouteCommonDetails,
	RouteCommonDetailsRefType,
	RouteCommonList,
	RouteCommonListRefType,
	RouteCommonMap,
	RouteCommonTabs,
	RouteCommonTimelineLegendPanel,
	RouteCommonToolbar,
	RouteCommonVesselCard
} from "./components";
import { CommonVesselArrival } from "@/common";

const RouteCommon: FC<{}> = (props) => {
	const {
		activeTab,
		handleTabSelect,
		handleAisSelect,
		handleLegSelect,
		activeLegVessel,
		setActiveLegVessel,
		activeAisVessel,
		setActiveAisVessel,
		mapRef,
		meteoStatus,
		handleMeteoStatusChange,
		handleControllChange,
		handleMeteoTimeChange,
		handleRun,
		legendVisible,
		handleLegendVisibleChange,
		activePortVessel,
		setActivePortVessel,
		additional,
		activeAdditional,
		handleAdditionalSelect,
		handleAdditionalClose,
		handleAdditionalToolClose,
		activeDetailsOption,
		handleDetailsTabSelect,
		activeAreaVessel,
		setActiveAreaVessel,
		activeAreaItems,
		setActiveAreaItems,
		handleDetailsOptionCommit,
		activePortItem,
		handlePortItemCommit,
		handleVesselLoad,
		handlePortVesselSelect,
		handleAreaVesselSelect,
		getAisDataForImo,
		handleVesselTrackLoad,
		handleAdditionalCommit,
		getMeteoCalendarEndTime,
		visibleRows,
		handleTabShow
	} = useRouteCommonIndex("ais");

	const detailsRef = useRef<RouteCommonDetailsRefType>(null);
	const listRef = useRef<RouteCommonListRefType>(null);

	console.log("activeAdditional", {
		activeAdditional,
		additional,
		hide: additional?.currentTools?.includes("ais")
	});

	return (
		<RouteCommonProvider
			value={{
				activeAisVessel,
				activeLegVessel,
				setActiveAisVessel,
				setActiveLegVessel,
				activeAreaVessel,
				setActiveAreaVessel,
				activePortVessel,
				setActivePortVessel,
				activeAreaItems,
				setActiveAreaItems,
				activeDetailsOption,
				onDetailsOptionCommit: handleDetailsOptionCommit,
				activePortItem,
				onPortItemCommit: handlePortItemCommit,
				onVesselTrackLoad: handleVesselTrackLoad,
				onAdditionalCommit: handleAdditionalCommit,
				getMeteoCalendarEndTime
			}}
		>
			<div className={classnames(style["fleet-routeCommon"])}>
				<div className="routeCommon-container">
					<RouteCommonList
						ref={listRef}
						activeTab={activeTab}
						visibleRows={visibleRows}
						onVesselLoad={handleVesselLoad}
						onTabSelect={handleTabSelect}
						onAisSelect={handleAisSelect}
						onLegSelect={handleLegSelect}
						onAreaVesselReset={(items) => {
							detailsRef?.current?.areaVesselRef?.current?.onAreaVesselReset?.(items);
						}}
						onPortVesselReset={(hasPort) => {
							detailsRef?.current?.portVesselRef?.current?.onPortVesselReset?.(hasPort);
						}}
					/>
					<RouteCommonTabs
						activeTab={activeTab}
						onSelect={handleTabSelect}
						visibleRows={visibleRows}
						onShow={handleTabShow}
					/>
					<RouteCommonMap
						ref={mapRef}
						children={
							<RouteCommonVesselCard
								activeAisItem={activeAisVessel}
								activeLegItem={activeLegVessel}
								activePortItem={activePortVessel}
								activeAreaItem={activeAreaVessel}
								onSelect={handleAdditionalSelect}
								onClose={handleAdditionalClose}
								onTrackRefresh={getAisDataForImo}
								onAisListReset={() => {
									listRef?.current?.aisListRef?.current?.onAisListReset?.();
								}}
							/>
						}
						onIsAisCollectGet={(item) => {
							return listRef?.current?.aisListRef?.current?.onGetFollow(item);
						}}
					/>
					<RouteCommonAisLine
						hide={additional?.currentTools?.includes("ais")}
						imo={activeAdditional?.imo}
						rangeOption={additional?.rangeOption}
						onClose={() => {
							handleAdditionalToolClose("ais");
						}}
					/>
					<RouteCommonToolbar
						meteoStatus={meteoStatus}
						onMeteoStatusChange={handleMeteoStatusChange}
						onControllChange={handleControllChange}
						legendVisible={legendVisible}
						onLegendVisibleChange={handleLegendVisibleChange}
						onRangeSelect={(type) => {
							mapRef?.current?.onRangeControlSelect?.(type);
						}}
						onPointSelect={(value) => {
							mapRef?.current?.onPointControlSelect(value);
						}}
					/>
					<RouteCommonTimelineLegendPanel
						meteoStatus={meteoStatus}
						visible={legendVisible}
						onMeteoTimeChange={handleMeteoTimeChange}
						onRun={handleRun}
					/>
					<RouteCommonDetails
						ref={detailsRef}
						activeVoyageTab={activeTab}
						activeOption={activeDetailsOption}
						onDetailsTabSelect={handleDetailsTabSelect}
						onVesselLoad={handleVesselLoad}
						onAreaVesselSelect={handleAreaVesselSelect}
						onPortVesselSelect={handlePortVesselSelect}
					/>
					<CommonVesselArrival
						hide={!additional?.currentTools?.includes("particular")}
						type="details"
						imo={activeAdditional?.imo}
						onClose={() => {
							handleAdditionalToolClose("particular");
						}}
					/>
				</div>
			</div>
		</RouteCommonProvider>
	);
};

export default RouteCommon;
