import { FleetOptionType } from "@/types/advancedType"
import { BunkerClauseType } from "./type"
import { InitialUppercase } from "@/types/string"

export const columns: string[] = [
  null,
  "HSFO 3.5%S",
  "LSFO 0.5%S",
  "MDO 0.5%S",
  "MGO 0.1%S",
  "BOR About",
  "BOR Price"
]

export const options: FleetOptionType<
  InitialUppercase<BunkerClauseType["borPrice"]>,
  BunkerClauseType["borPrice"]
>[] = [
    { label: "Different", value: "different" },
    { label: "Same", value: "same" }
  ]

export const initialBunkerClause = (item?: Partial<BunkerClauseType>): BunkerClauseType => {
  return {
    hsFoQuantity: item?.hsFoQuantity ?? "",
    lsFoQuantity: item?.lsFoQuantity ?? "",
    mdoQuantity: item?.mdoQuantity ?? "",
    mgoQuantity: item?.mgoQuantity ?? "",
    borAbout: item?.borAbout ?? 5,
    borPrice: item?.borPrice ?? "same",
    bodPrice: item?.bodPrice ?? "",
    hsFoPrice: item?.hsFoPrice ?? "",
    lsFoPrice: item?.lsFoPrice ?? "",
    mdoPrice: item?.mdoPrice ?? "",
    mgoPrice: item?.mgoPrice ?? "",
    bunkerClause: item?.bunkerClause ?? "",
  }
}