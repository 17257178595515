export enum ArrivalAisStatusMap {
	Running = 1,
	Mooring,
	Runaway,
	Disable = 4,
	"Draft Limits",
	"At Berth",
	"Stranded",
	Unknown = 15
}
// 船舶状态:1.机动船在航;2.锚泊;3.失控;4.操纵能力受限;5.限于吃水;6.系泊;7.搁浅;8.正在捕鱼作业;9.帆船在航;10.高速船留用;11.地效翼船留用;15未知

export enum ArrivalShipStatusMap {
	// 0 未知
	Unknown,
	// 1（在海）重载运行
	Laden,
	// 2（在海）空载运行
	Ballast,
	// 3（在港）装货
	Loading,
	// 4（在港）卸货
	Discharging,
	// 5（在港）等待装货
	"Waiting Loading",
	// 6（在港）等待卸货
	"Waiting Discharging",
	// 7（在港）修船
	Dockyard
}

// 船舶类型枚举
export enum ArrivalVesselTypeMap {
	TANKER,
	LNG,
	BULK,
	PCC,
	CNTR
}

// 船舶二级类型枚举
export enum ArrivalVesselSubTypeMap {
	All = -1,
	VLCC = 1,
	Aframax,
	Suezmax,
	"145K" = 11,
	"160K",
	"174K",
	"200+K",
	"Other",
	VLOC = 21,
	Capesize,
	"Post-Panamax",
	Panamax,
	Supramax,
	Handymax,
	Handysize,
	"Small Bulk",
	PCC = 31,
	CNTR = 41
}

// 脱硫塔安装状态
export enum ArrivalSoxScrubberStatusMap {
	"否",
	"是"
}

// 状态
export enum ArrivalStatusMap {
	Orderbook = 1,
	"In Service",
	Repair,
	Storage,
	"Laid Up",
	Abnormal,
	FPSO,
	Decommisioned
}

// 节能型标识
export enum ArrivalEcoEngineMap {
	"非节能型",
	"节能型"
}

// 节能型版本
export enum ArrivalEnergySavingTypeMap {
	"第一代节能型" = 1,
	"第二代节能型"
}

// 贸易类型
export enum ArrivalTradeTypeMap {
	"内贸" = 1,
	"外贸",
	"内外贸"
}

// 是否有传动装置
export enum ArrivalGearMap {
	"否",
	"是",
	"无(未知)" = null
}

export enum ArrivalPurposeMap {
	"Start" = 1,
	"Load",
	"Bunker",
	"Discharge",
	"Repair",
	"Other"
}
