import React, { useImperativeHandle, forwardRef, Suspense } from "react";
import style from "./index.module.less";
import { RouteCommonDetailsProps, RouteCommonDetailsRefType } from "./type";
import useIndex from "./hooks/useIndex";
import {
	RouteCommonAreaVessel,
	RouteCommonDetailsTabs,
	RouteCommonPortCall,
	RouteCommonPortVessel
} from "./components";
import { FleetLoading } from "@/components";

const RouteCommonDetails = forwardRef<RouteCommonDetailsRefType, RouteCommonDetailsProps>(
	(
		{
			activeVoyageTab,
			activeOption,
			onDetailsTabSelect,
			onVesselLoad,
			onAreaVesselSelect,
			onPortVesselSelect
		},
		ref
	) => {
		const { areaVesselRef, portVesselRef, headerRef, wrapperRef, handlePortCallOpen } = useIndex();
		useImperativeHandle(ref, () => {
			return {
				areaVesselRef: areaVesselRef,
				portVesselRef: portVesselRef
			};
		});
		return (
			<div className={style["routeCommon-details"]} ref={wrapperRef} id="routeCommon-details">
				<div className="routeCommon-details-header" ref={headerRef}>
					<RouteCommonDetailsTabs
						activeTab={activeOption?.activeTab}
						onSelect={onDetailsTabSelect}
					/>
				</div>
				<div className="routeCommon-details-container">
					<Suspense fallback={<FleetLoading loading />}>
						<RouteCommonAreaVessel
							ref={areaVesselRef}
							onPortCallOpen={handlePortCallOpen}
							onVesselLoad={onVesselLoad}
							onVesselSelect={onAreaVesselSelect}
						/>
					</Suspense>
					<RouteCommonPortVessel
						ref={portVesselRef}
						onPortCallOpen={handlePortCallOpen}
						onVesselLoad={onVesselLoad}
						onVesselSelect={onPortVesselSelect}
					/>
					<RouteCommonPortCall />
				</div>
			</div>
		);
	}
);

export default RouteCommonDetails;
