import React, { FC, Fragment, useMemo, useState } from "react";
import style from "./index.module.less";
import { RouteCommonVesselCardOperatorProps, RouteCommonVesselCardOperatorToolType } from "./type";
import { FleetAisDateSelect } from "@/common";
import { options, selectTimeOptions, toolOptions } from "./source";
import { Icon } from "@/_components";
import classNames from "classnames";
import { RouteCommonGroupSelect, RouteCommonGroupSelectProps } from "./components";
import { FleetRangePicker } from "@/components";
import dayjs from "dayjs";

const RouteCommonVesselCardOperator: FC<RouteCommonVesselCardOperatorProps> = ({
	mode,
	item,
	activeTime,
	rangeOption,
	onRangeCommit,
	onOpen,
	onSelect,
	onPortCallOpen,
	onSave
}) => {
	const [open, setOpen] = useState(false);
	const tools = useMemo(() => {
		return mode === "ais"
			? toolOptions
			: toolOptions?.filter((toolItem) => toolItem?.value !== "save");
	}, [mode]);

	const handleToolSelect = (type: RouteCommonVesselCardOperatorToolType) => {
		switch (true) {
			case type === "save":
				// onSave?.(item);
				if (item?.isCollect) {
					onSave?.(item);
					return;
				}
				setOpen(true);
				break;
			case type === "portCall":
				onPortCallOpen?.(item);
				break;
			default:
				onOpen?.(type);
				break;
		}
	};

	const handleSaveCollection: RouteCommonGroupSelectProps["onConfirm"] = (groupItem) => {
		onSave?.(item, groupItem?.id);
	};

	return (
		<Fragment>
			<ul className={style["routeCommon-vesselCard-tracking"]}>
				<li className="tracking-item timeSelect">
					<span className="tracking-item--label">Tracking:</span>
					<FleetAisDateSelect
						activeTime={rangeOption?.startDate && rangeOption?.endDate ? null : activeTime}
						onSelect={onSelect}
						options={selectTimeOptions}
					/>
				</li>
				<FleetRangePicker
					className="tracking-item"
					value={[
						rangeOption?.startDate ? dayjs(rangeOption?.startDate) : null,
						rangeOption?.endDate ? dayjs(rangeOption?.endDate) : null
					]}
					onChange={onRangeCommit}
					disabledDate={(date) => {
						return date?.isAfter(dayjs());
					}}
					allowClear
					showHour
					showMinute
					showTime={{
						format: "YYYY-MM-DD HH:mm"
					}}
				/>
				{options?.map((optionItem, optionIndex) => {
					return (
						<li className="tracking-item" key={optionItem?.value}>
							<span className="tracking-item--label">{optionItem?.label} :</span>
							<span className={`tracking-item--value ${optionItem?.value}`}>
								{optionItem?.formatter(item)}
							</span>
						</li>
					);
				})}
			</ul>
			<ul className={style["routeCommon-vesselCard-operator"]}>
				{tools?.map((toolItem, index) => {
					if (toolItem?.value === "save" && !item?.isCollect) {
						return (
							<RouteCommonGroupSelect
								open={open}
								onOpenChange={setOpen}
								onConfirm={handleSaveCollection}
							>
								<li
									className={classNames("operator-item", toolItem?.value)}
									key={toolItem?.value}
									onClick={() => handleToolSelect?.(toolItem?.value)}
								>
									<Icon type={toolItem?.icon} size={16} className="operator-item-icon" />
									<span>{toolItem?.label}</span>
								</li>
							</RouteCommonGroupSelect>
						);
					}
					return (
						<li
							className={classNames("operator-item", toolItem?.value, {
								active: toolItem?.value === "save" && !!item?.isCollect
							})}
							key={toolItem?.value}
							onClick={() => handleToolSelect?.(toolItem?.value)}
						>
							<Icon type={toolItem?.icon} size={16} className="operator-item-icon" />
							<span>{toolItem?.label}</span>
						</li>
					);
				})}
			</ul>
		</Fragment>
	);
};

export default RouteCommonVesselCardOperator;
