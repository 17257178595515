import React, { FC } from "react";
import style from "./index.module.less";
import { CommonOtherExpensesProps } from "./type";
import { FleetPrompt } from "@/common";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { useTranslation } from "react-i18next";
import MovementTable from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table";
import { Button } from "@/_components";

const CommonOtherExpenses: FC<CommonOtherExpensesProps> = ({
	hide,
	type,
	voyageType,
	onClose,
	primaryId,
	onConfirm
}) => {
	const { t } = useTranslation();
	const { loading, dataSource, summary, change, commit, remove, add, saveCommonOtherExpenses } =
		useIndex(type, hide, primaryId, voyageType, onConfirm, onClose);
	return (
		<FleetPrompt hide={hide} mode="fixed" loading={loading}>
			<div className={style["common-otherExpenses"]}>
				<div className="prompt-header--container">
					<span className="header-title">{t("voyageManagement.movement.otherExpenses")}</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<MovementTable
					bordered
					className="otherExpenses-container"
					columns={columns}
					dataSource={dataSource}
					summary={summary}
					actions={{
						onChange: change,
						onDelete: remove,
						onCommit: commit
					}}
				/>
				<Button type="secondary" onClick={add} size="small">
					Add Expense
				</Button>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={saveCommonOtherExpenses}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default CommonOtherExpenses;
