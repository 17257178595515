import React, { useEffect } from "react";
import style from "./index.module.less";
import { DatabaseContactEditItemType, DatabaseContactEditPorps } from "./type";
import { CommonRoleWrap, FleetPrompt } from "@/common";
import {
	FleetButton,
	FleetCascader,
	FleetIcon,
	FleetLoading,
	FleetTextarea,
	FleetVerifyInput
} from "@/components";
import useContact from "@/hook/database/contact/useContact";
import { Button } from "@/_components";
import { businessOption } from "@/common/Newly";

const DatabaseContactEdit: React.FC<DatabaseContactEditPorps> = ({ hide, onClose, id }) => {
	const { dataSource, change, editContact, getContact, clear, loading } = useContact(onClose);

	useEffect(() => {
		if (hide) {
			id && clear();
			return;
		} else {
			getContact(id);
		}
	}, [hide]);

	return (
		<FleetPrompt hide={hide} drawer>
			<div className={style["database-contactEdit"]}>
				<FleetLoading loading={loading} />
				<div className="contactEdit-header">
					<div className="header-title">
						<span>Contacts /</span>
						<span className="businessType">&nbsp;{dataSource?.businessType}</span>
					</div>
					<div className="header-operator">
						<FleetIcon
							outerLayerClassname="operator-close"
							type="FleetCloseIcon"
							onClick={onClose}
						/>
						<CommonRoleWrap roleStr="contact:list:save">
							<Button prefixIcon="fleet-save" size="small" type="secondary" onClick={editContact}>
								Save
							</Button>
						</CommonRoleWrap>
					</div>
				</div>
				<div className="contactEdit-container">
					<FleetVerifyInput
						label="Contact Name"
						needVerity
						value={dataSource?.name}
						onChange={(val: string) => {
							change("name", val);
						}}
					/>
					<div className="contactEdit-item">
						<FleetVerifyInput
							value={dataSource?.companyName}
							containerClassname="input-container"
							className="input"
							onChange={(val: string) => {
								change("companyName", val);
							}}
							label="Company Name"
							needVerity
						/>
						<FleetCascader
							value={[dataSource?.businessType]}
							onClear={() => {
								change("businessType", "");
							}}
							className="cascader-container"
							onChange={(val: string[]) => {
								change("businessType", val?.[0]);
							}}
							options={businessOption}
						/>
					</div>
					<FleetVerifyInput
						label="MOB"
						value={dataSource?.mobilePhone1}
						onChange={(val: string) => {
							change("mobilePhone1", val);
						}}
					/>
					<FleetVerifyInput
						label="MOB2"
						value={dataSource?.mobilePhone2}
						onChange={(val: string) => {
							change("mobilePhone2", val);
						}}
					/>
					<FleetVerifyInput
						label="TEL"
						value={dataSource?.landline1}
						onChange={(val: string) => {
							change("landline1", val);
						}}
					/>
					<FleetVerifyInput
						label="TEL2"
						value={dataSource?.landline2}
						onChange={(val: string) => {
							change("landline2", val);
						}}
					/>
					<FleetVerifyInput
						label="FAX"
						value={dataSource?.faxNumber}
						onChange={(val: string) => {
							change("faxNumber", val);
						}}
					/>
					<FleetVerifyInput
						label="Email"
						needVerity
						value={dataSource?.email}
						onChange={(val: string) => {
							change("email", val);
						}}
					/>
				</div>
				<FleetTextarea
					value={dataSource?.remark}
					onChange={(val: string) => {
						change("remark", val);
					}}
					label="Remakes"
					placeholder="Please input the remarks"
					className="contactEdit-textarea"
				/>
			</div>
		</FleetPrompt>
	);
};

export default DatabaseContactEdit;
