import { useEffect, useState } from "react";
import {
	RouteCommonRangingControllTabType,
	RouteCommonToolbarControllItemType,
	RouteCommonToolbarControllKeys,
	RouteCommonToolbarProps
} from "../type";

const useIndex = (
	onControllChange: RouteCommonToolbarProps["onControllChange"],
	onRangeSelect: RouteCommonToolbarProps["onRangeSelect"],
	onPointSelect: RouteCommonToolbarProps["onPointSelect"]
) => {
	const [controllStatus, setControllStatus] = useState<RouteCommonToolbarControllKeys[]>([]);

	const [activeRangeIndex, setActiveRangeIndex] = useState<RouteCommonRangingControllTabType>(null);

	const [pointStatus, setPointStatus] = useState<boolean>(false);

	const handleControllChange = (item: RouteCommonToolbarControllItemType) => {
		setControllStatus((prev) => {
			const index = prev?.findIndex((selectItem) => selectItem === item?.value);
			if (index === -1) {
				prev?.push(item?.value);
			} else {
				prev?.splice(index, 1);
			}
			onControllChange?.(item?.layerIds, index === -1);
			return [...prev];
		});
	};

	const handleRangeSelect: RouteCommonToolbarProps["onRangeSelect"] = (type) => {
		switch (true) {
			case !type:
			case activeRangeIndex === type:
				setActiveRangeIndex(null);
				break;
			default:
				setActiveRangeIndex(type);
				break;
		}
		onRangeSelect?.(activeRangeIndex === type ? null : type);
	};

	const handlePointSelect: RouteCommonToolbarProps["onPointSelect"] = (value) => {
		setPointStatus(value);
		onPointSelect?.(value);
	};

	const handleKeyUp = (event: KeyboardEvent) => {
		console.log("event", event);
		if (event.key === "Escape") {
			debugger;
			handleRangeSelect?.(null);
			event?.preventDefault();
		}
	};

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp, false);
		return () => {
			document.removeEventListener("keyup", handleKeyUp, false);
		};
	}, []);

	return {
		activeRangeIndex,
		controllStatus,
		handleControllChange,
		handleRangeSelect,
		pointStatus,
		handlePointSelect
	};
};

export default useIndex;
