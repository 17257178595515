import { editContactAction, getContactAction } from "@/action/dataBase/contact"
import {
  type DatabaseContactEditItemType,
  type DatabaseContactEditItemChangeEvent,
  type DatabaseContactEditItemCommitEvent,
  initialDatabaseContactEditItem
} from "@/featrue/database/contact/edit/type"
import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { checkContactKeys } from "@/pages/database/business/contact/type"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const useContact = (
  onClose?:() => void
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<DatabaseContactEditItemType>({
    ...initialDatabaseContactEditItem
  })

  const change: DatabaseContactEditItemChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: DatabaseContactEditItemCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init = (item: DatabaseContactEditItemType) => {
    setDataSource(item)
  }

  const clear = () => {
    setDataSource({...initialDatabaseContactEditItem})
  }

  const check = () => {
    let checkKeys = "";
    let checked = true;
    for (let key of checkContactKeys) {
      if (dataSource[key] !== 0 && !dataSource[key]) {
        checkKeys = key;
        checked = false;
        break
      }
    }

    return {
      checkKeys,
      checked
    }
  }

  const getContactSuccess = (response) => {
    setLoading(false)
    init({
      name: response?.data?.name || "",
      companyName: response?.data?.companyName || "",
      mobilePhone1: response?.data?.mobilePhone1 || "",
      mobilePhone2: response?.data?.mobilePhone2 || "",
      landline1:response?.data?.landline1 ||  "",
      landline2: response?.data?.landline2 || "",
      faxNumber: response?.data?.faxNumber || "",
      email: response?.data?.email || "",
      remark: response?.data?.remark || "",
      businessType: response?.data?.businessType || "",
      id:response?.data?.id || ""
    })
  }

  const getContact = (id:string | number) => {
    if(!id) return
    getContactAction(
      {id:id},
      editContactFront,
      getContactSuccess,
      editContactError,
      dispatch,
      navigate
    )
  }

  const editContactFront = () => {
    setLoading(true)
  }

  const editContactSuccess = (response) => {
    setLoading(false)
    reminder("success",response?.msg)
    onClose?.()
  }

  const editContactError = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const editContact = () => {
    const { checkKeys, checked } = check();
    if (!checked) {
      reminder("error", `Field ${checkKeys} is required`);
      return
    }
    if(!dataSource.id) {
      reminder("error","There is an issue with the selected contact data, please reselect it")
      onClose?.();
      return
    }
    editContactAction(
      { ...dataSource },
      editContactFront,
      editContactSuccess,
      editContactError,
      dispatch,
      navigate
    )
  }

  return {
    dataSource,
    change,
    commit,
    getContact,
    loading,
    editContact,
    clear
  }
}

export default useContact