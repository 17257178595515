import React, { createContext, useContext } from "react";
import {
	RouteAreaItemType,
	RouteCommonAisItemType,
	RouteCommonAreaVesselItemType,
	RouteCommonDetailsProps,
	RouteCommonPortVesselItemType,
	RouteLegItemType,
	RoutePortTabItemType
} from "./components";
import { CommonCommitEvent } from "@/types/event";
import { RouteCommonAdditionalItemType, RouteCommonVesselTrackLoadEvent } from "./type";
import { Dayjs } from "dayjs";

export type RouteCommonProviderProps = {
	value: {
		abortController?: AbortController;
		activeLegVessel?: RouteLegItemType;
		setActiveLegVessel?: (val: RouteLegItemType) => void;
		activeAisVessel?: RouteCommonAisItemType;
		setActiveAisVessel?: (val: RouteCommonAisItemType) => void;
		activeAreaVessel?: RouteCommonAreaVesselItemType;
		setActiveAreaVessel?: (val: RouteCommonAreaVesselItemType) => void;
		activePortVessel?: RouteCommonPortVesselItemType;
		setActivePortVessel?: (val: RouteCommonPortVesselItemType) => void;
		activeAreaItems?: RouteAreaItemType[];
		setActiveAreaItems?: (vals: RouteAreaItemType[]) => void;
		activeDetailsOption?: RouteCommonDetailsProps["activeOption"];
		onDetailsOptionCommit?: CommonCommitEvent<RouteCommonDetailsProps["activeOption"]>;
		activePortItem?: {
			type: RoutePortTabItemType;
			portName: string;
			portCode: string;
			startDate: string;
			endDate: string;
		};
		onPortItemCommit?: CommonCommitEvent<RouteCommonProviderProps["value"]["activePortItem"]>;
		onVesselTrackLoad?: RouteCommonVesselTrackLoadEvent;
		onAdditionalCommit?: CommonCommitEvent<RouteCommonAdditionalItemType>;
		getMeteoCalendarEndTime?: () => Dayjs;
	};
	children?: React.ReactNode;
};

export const RouteCommonContext = createContext<RouteCommonProviderProps["value"]>({});

const useRouteCommonContext = () => {
	const context = useContext(RouteCommonContext);
	return context;
};

const RouteCommonProvider: React.FC<RouteCommonProviderProps> = ({ value, children }) => {
	return (
		<RouteCommonContext.Provider
			value={{
				...value
			}}
		>
			{children}
		</RouteCommonContext.Provider>
	);
};

export { RouteCommonProvider, useRouteCommonContext };
