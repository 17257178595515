import { CommonOptionType, FleetOptionType } from "@/types/advancedType";
import { RouteCommonToolbarControllItemType, RouteCommonToolbarMeteoMap } from "./type";
import { IconProps } from "@/_components/Icon/interface";

type MeteorologicalKeys = keyof typeof RouteCommonToolbarMeteoMap;

export const meteorologicalOptions: (FleetOptionType<MeteorologicalKeys, MeteorologicalKeys> & {
	icon: IconProps["type"];
})[] = [
	{
		label: "Wind",
		value: "Wind",
		icon: "fleet-wind"
	},
	{
		label: "Wave",
		value: "Wave",
		icon: "fleet-sigwave"
	},
	{
		label: "Swell",
		value: "Swell",
		icon: "fleet-swell"
	},
	{
		label: "Current",
		value: "Current",
		icon: "fleet-current"
	},
	{
		label: "Typhoon",
		value: "Typhoon",
		icon: "fleet-typhoon"
	},
	{
		label: "Pressure",
		value: "Pressure",
		icon: "fleet-pressure"
	}
];

export const controllOptions: RouteCommonToolbarControllItemType[] = [
	{ label: "Ports", value: "Ports", layerIds: ["ports"], icon: "fleet-ports" },
	{
		label: "Load Line",
		value: "Loadline",
		layerIds: ["load-line-point", "load-line-polygon"],
		icon: "fleet-loadline"
	},
	{
		label: "ECA Zones",
		value: "Eca",
		layerIds: ["eca-zones-point", "eca-zones-polygon"],
		icon: "fleet-ecazones"
	},
	{
		label: "HRA/WAR Zones",
		value: "War",
		layerIds: ["war-zones-point", "war-zones-polygon"],
		icon: "fleet-hrawarzones"
	}
];

export const rangingControllOptions: CommonOptionType<
	"Rhumb Line" | "Great Circle",
	"straight" | "circle",
	{
		icon: IconProps["type"];
	}
>[] = [
	{ label: "Rhumb Line", value: "straight", icon: "fleet-line" },
	{ label: "Great Circle", value: "circle", icon: "fleet-circle1" }
];

export const legendVisibleOptions: CommonOptionType<
	"Legend" | "Point",
	"legend" | "point",
	{ icon: IconProps["type"] }
>[] = [
	{ label: "Legend", value: "legend", icon: "fleet-legend_hide" },
	{ label: "Point", value: "point", icon: "fleet-point_local" }
];
