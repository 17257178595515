import { FleetOptionType } from "@/types/advancedType";
import { RouteCommonSelectTimeKeys, RouteCommonVesselCardItemType } from "../../type";
import { ExtendsKeys } from "@/types";
import { dateToUtcString } from "@/tools/date";
import { ArrivalShipStatusMap } from "@/common/Select";
import { formatThousandthNumber } from "@/tools/amount";
import { IconProps } from "@/_components/Icon/interface";
import { RouteCommonVesselCardOperatorToolType } from "./type";

export const selectTimeOptions: FleetOptionType<
	"3" | "7" | "14" | "30" | "60" | "90" | "1Y" | "2Y",
	RouteCommonSelectTimeKeys
>[] = [
	{
		label: "3",
		value: "3/day"
	},
	{
		label: "7",
		value: "7/day"
	},
	{
		label: "14",
		value: "14/day"
	},
	{
		label: "30",
		value: "30/day"
	},
	{
		label: "60",
		value: "60/day"
	},
	{
		label: "90",
		value: "90/day"
	},
	{
		label: "1Y",
		value: "1/year"
	},
	{
		label: "2Y",
		value: "2/year"
	}
];

export const options: (FleetOptionType<
	"AIS Latest Time" | "Speed" | "ETA" | "IMO" | "Status" | "DWT",
	ExtendsKeys<
		keyof RouteCommonVesselCardItemType,
		"speed" | "eta" | "imo" | "shipStatus" | "dwt" | "updateTime"
	>
> & {
	formatter: (item: RouteCommonVesselCardItemType) => string;
})[] = [
	{
		label: "AIS Latest Time",
		value: "updateTime",
		formatter: (item) => {
			return item?.updateTime ? dateToUtcString(item?.updateTime) : null;
		}
	},
	{
		label: "Speed",
		value: "speed",
		formatter: (item) => {
			return `${item?.speed ?? 0} KTS`;
		}
	},
	{
		label: "ETA",
		value: "eta",
		formatter: (item) => {
			return item?.eta ? dateToUtcString(item?.eta) : null;
		}
	},
	{
		label: "IMO",
		value: "imo",
		formatter: (item) => {
			return item?.imo;
		}
	},
	{
		label: "Status",
		value: "shipStatus",
		formatter: (item) => {
			return ArrivalShipStatusMap[item?.shipStatus ?? item?.status];
		}
	},
	{
		label: "DWT",
		value: "dwt",
		formatter: (item) => {
			return formatThousandthNumber(item?.dwt, 0, 0);
		}
	}
];

export const toolOptions: (FleetOptionType<
	"Save" | "Portcall" | "Particular" | "Speed",
	RouteCommonVesselCardOperatorToolType
> & { icon: IconProps["type"] })[] = [
	{
		label: "Save",
		value: "save",
		icon: "fleet-collected"
	},
	{
		label: "Portcall",
		value: "portCall",
		icon: "fleet-ports"
	},
	{
		label: "Particular",
		value: "particular",
		icon: "fleet-particular"
	},
	{
		label: "Speed",
		value: "ais",
		icon: "fleet-chart"
	}
];
