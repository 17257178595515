import { CommonInitItemEvent } from "@/types/event";
import { AparFinancialItemType } from "./type";

export const initialFinancialItem: CommonInitItemEvent<AparFinancialItemType> = (item) => {
	return {
		id: item?.id ?? null,
		amount: item?.amount ?? "",
		arAp: item?.arAp ?? null,
		attachment: item?.attachment ?? [],
		dataId: item?.dataId ?? null,
		dataType: item?.dataType ?? null,
		invoiceAmount: item?.invoiceAmount ?? "",
		latestFinancialDate: item?.latestFinancialDate ?? null,
		status: item?.status ?? "Unsettled",
		voyageType: item?.voyageType ?? null
	};
};

export const checkKeys: (keyof AparFinancialItemType)[] = ["amount", "latestFinancialDate"];
