import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonAisGroupItemProps } from "./type";
import { Icon, Popover } from "@/_components";
import { RouteCommonAisItem } from "./components";
import { FleetToast } from "@/common";
import { RouteCommonGroupAdd } from "@/pages/routeCommon/common";
import classNames from "classnames";
import { RouteCommonAisItemColorMap } from "@/pages/routeCommon/source";

const RouteCommonAisGroupItem: FC<RouteCommonAisGroupItemProps> = ({
	item,
	activeItem,
	expandRows,
	onSelect,
	onEdit,
	onDelete,
	onCollection,
	onRowExpand
}) => {
	return (
		<div
			className={classNames(style["routeCommon-aisGroupItem"], {
				[style["routeCommon-aisGroupItem--expand"]]: expandRows?.includes(item?.groupId)
			})}
			id={`routeCommon-aisGroup--${item?.groupId}`}
		>
			<div className="aisGroupItem-header">
				<div className="aisGroupItem-groupEdit">
					<Icon
						type="fleet-back"
						className="aisGroupItem-icon groupEdit-expandIcon"
						onClick={() => {
							if (item?.dataList?.length <= 0) return;
							onRowExpand?.(item?.groupId);
						}}
					/>
					<span
						style={{
							background:
								RouteCommonAisItemColorMap[item?.color] ?? RouteCommonAisItemColorMap.Purple
						}}
					></span>
					<span>
						{item?.groupName} ({item?.dataList?.length})
					</span>
					<RouteCommonGroupAdd
						children={
							<Icon size={14} className="aisGroupItem-icon" type="fleet-edit2" color="#757575" />
						}
						mode="edit"
						onConfirm={onEdit}
						groupItem={item}
					/>
				</div>
				<Popover
					children={
						<Icon size={14} className="aisGroupItem-icon" type="fleet-delete" color="#757575" />
					}
					render={(setIsOpen) => {
						return (
							<FleetToast
								label="Are you sure delete this group?"
								onCancel={() => {
									setIsOpen(false);
								}}
								onConfirm={() => {
									onDelete?.(item);
									setIsOpen(false);
								}}
							/>
						);
					}}
				/>
			</div>
			<div className="aisGroupItem-container">
				{item?.dataList?.map((aisItem, aisIndex) => {
					return (
						<RouteCommonAisItem
							key={aisIndex}
							item={aisItem}
							activeItem={activeItem}
							onSelect={onSelect}
							onCollection={onCollection}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default RouteCommonAisGroupItem;
