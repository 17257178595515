import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonToolbarMeteoSwitchMap, RouteCommonToolbarProps } from "./type";
import {
	controllOptions,
	rangingControllOptions,
	meteorologicalOptions,
	legendVisibleOptions
} from "./source";
import { Icon, Tooltip } from "@/_components";
import classnames from "classnames";
import useIndex from "./hooks/useIndex";

const RouteCommonToolbar: FC<RouteCommonToolbarProps> = ({
	meteoStatus,
	onMeteoStatusChange,
	onControllChange,
	onRangeSelect,
	legendVisible,
	onLegendVisibleChange,
	onPointSelect
}) => {
	const {
		activeRangeIndex,
		controllStatus,
		handleControllChange,
		handleRangeSelect,
		pointStatus,
		handlePointSelect
	} = useIndex(onControllChange, onRangeSelect, onPointSelect);

	return (
		<div className={style["routeCommon-toolbar"]}>
			<ul className="meteorological-actions">
				{meteorologicalOptions?.map((item, index) => {
					return (
						<Tooltip
							key={item?.value}
							trigger="hover"
							placement="left"
							title={item?.label}
							buttonProps={{
								size: "small",
								type: "icon",
								className: classnames("meteorological-item", {
									active: meteoStatus[item?.value] === RouteCommonToolbarMeteoSwitchMap.On
								}),
								onClick: () => {
									onMeteoStatusChange?.(item?.value);
								},
								children: (
									<Icon
										type={item?.icon}
										color={
											meteoStatus[item?.value] === RouteCommonToolbarMeteoSwitchMap.On
												? "#ffffff"
												: "#355691"
										}
										size={16}
									/>
								)
							}}
						/>
					);
				})}
			</ul>
			<ul className="meteorological-actions">
				{controllOptions?.map((item, index) => {
					return (
						<Tooltip
							key={item?.value}
							trigger="hover"
							placement="left"
							title={item?.label}
							buttonProps={{
								size: "small",
								type: "icon",
								className: classnames("meteorological-item", {
									active: controllStatus?.includes(item?.value)
								}),
								onClick: () => {
									handleControllChange(item);
									// onMeteoStatusChange?.(item?.value, !meteoStatus?.[item?.value]);
								},
								children: (
									<Icon
										type={item?.icon}
										color={controllStatus?.includes(item?.value) ? "#ffffff" : "#355691"}
										size={16}
									/>
								)
							}}
						/>
					);
				})}
			</ul>
			<ul className="meteorological-actions">
				{rangingControllOptions?.map((item, index) => {
					return (
						<Tooltip
							key={item?.value}
							trigger="hover"
							placement="left"
							title={item?.label}
							buttonProps={{
								size: "small",
								type: "icon",
								className: classnames("meteorological-item", {
									active: activeRangeIndex === item?.value
								}),
								onClick: () => {
									handleRangeSelect(item?.value);
								},
								children: (
									<Icon
										type={item?.icon}
										color={activeRangeIndex === item?.value ? "#ffffff" : "#355691"}
										size={16}
									/>
								)
							}}
						/>
					);
				})}
			</ul>
			<ul className="meteorological-actions">
				{legendVisibleOptions?.map((item, index) => {
					return (
						<Tooltip
							key={item?.value}
							trigger="hover"
							placement="left"
							title={item?.label}
							buttonProps={{
								size: "small",
								type: "icon",
								className: classnames("meteorological-item", {
									active:
										(item?.value === "legend" && legendVisible) ||
										(item?.value === "point" && pointStatus)
								}),
								onClick: () => {
									// handleRangeSelect(item?.value);
									if (item?.value === "legend") {
										onLegendVisibleChange?.();
									}

									if (item?.value === "point") {
										handlePointSelect(!pointStatus);
									}
								},
								children: (
									<Icon
										type={
											item?.value === "legend" && legendVisible ? "fleet-legend_show" : item?.icon
										}
										color={
											(item?.value === "legend" && legendVisible) ||
											(item?.value === "point" && pointStatus)
												? "#ffffff"
												: "#355691"
										}
										size={16}
									/>
								)
							}}
						/>
					);
				})}
			</ul>
		</div>
	);
};

export default RouteCommonToolbar;
