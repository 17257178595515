import { RouteCommonMeteoType } from "../../type";
import { FleetOptionType } from "@/types/advancedType";
import { IconProps } from "@/_components/Icon/interface";

export enum RouteCommonToolbarMeteoMap {
	Wind = "wind",
	Wave = "wave",
	Swell = "swell1",
	Current = "current",
	Typhoon = "typhoon",
	Pressure = "pressure"
}

export enum RouteCommonToolbarMeteoSwitchMap {
	On = "on",
	Off = "off"
}

export type RouteCommonToolbarControllKeys = "Ports" | "Loadline" | "Eca" | "War";

export type RouteCommonRangingControllTabType = "straight" | "circle";

export type RouteCommonToolbarControllItemType = FleetOptionType<
	"Ports" | "Load Line" | "ECA Zones" | "HRA/WAR Zones",
	RouteCommonToolbarControllKeys
> & {
	icon: IconProps["type"];
	layerIds:
		| ["ports"]
		| ["load-line-point", "load-line-polygon"]
		| ["eca-zones-point", "eca-zones-polygon"]
		| ["war-zones-point", "war-zones-polygon"];
};

export type RouteCommonToolbarProps = {
	meteoStatus: Record<RouteCommonMeteoType, RouteCommonToolbarMeteoSwitchMap>;
	onMeteoStatusChange: (value: RouteCommonMeteoType) => void;
	onControllChange: (
		layerIds: RouteCommonToolbarControllItemType["layerIds"],
		isShow: boolean
	) => void;
	onRangeSelect: (type: RouteCommonRangingControllTabType) => void;
	legendVisible: boolean;
	onLegendVisibleChange: () => void;
	onPointSelect: (value: boolean) => void;
};
