import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { HandoverFormFixtureNotesProps } from "./type";
import {
	FleetDatePicker,
	FleetFormItem,
	FleetInput,
	FleetRangePicker,
	FleetTextarea,
	FleetVerifyInput
} from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { FleetTabbar } from "@/components/Tabs";
import { CargoUnitType, FreightType } from "@/types/common";
import { FleetCustomRadio } from "@/components/Radio";
import HandoverFormLoadMore from "../loadMore";
import { CommonContactSelect } from "@/common";
import { CommonCompanySelect } from "@/common/Select";

const HandoverFormFixtureNotes: FC<HandoverFormFixtureNotesProps> = ({
	currentId,
	item,
	onChange,
	onCommit,
	onContactOpen
}) => {
	return (
		<div
			id={currentId}
			className={classnames("handoverForm--common", style["handoverForm-fixtureNotes"])}
		>
			<span className="handoverForm--common--header">Fixture Notes</span>
			<div className="fixtureNotes-container">
				<FleetFormItem label="Charterer Name" needVerity>
					<CommonContactSelect
						showKey="companyName"
						primaryKey="id"
						activeItems={{
							id: item?.chartererId,
							companyName: item?.chartererName
						}}
						onFinish={(newNoItem, type) => {
							onCommit?.({
								chartererId: newNoItem?.id ?? null,
								chartererName: newNoItem?.companyName ?? ""
							});
						}}
						contactParmas={{ businessType: "Charterer" }}
						contactActions={{
							onOpen: () => onContactOpen?.({ hide: false, type: "Charterer" })
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Shipper Name">
					<CommonContactSelect
						showKey="companyName"
						primaryKey="id"
						activeItems={{
							id: item?.shipperId,
							companyName: item?.shipperName
						}}
						onFinish={(newNoItem, type) => {
							onCommit?.({
								shipperId: newNoItem?.id ?? null,
								shipperName: newNoItem?.companyName ?? ""
							});
						}}
						contactParmas={{ businessType: "Shipper" }}
						contactActions={{
							onOpen: () => onContactOpen?.({ hide: false, type: "Shipper" })
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Broker Name">
					<CommonContactSelect
						showKey="companyName"
						primaryKey="id"
						activeItems={{
							id: item?.brokerId,
							companyName: item?.brokerName
						}}
						onFinish={(newNoItem, type) => {
							onCommit?.({
								brokerId: newNoItem?.id ?? null,
								brokerName: newNoItem?.companyName ?? ""
							});
						}}
						contactParmas={{ businessType: "Broker" }}
						contactActions={{
							onOpen: () => onContactOpen?.({ hide: false, type: "Broker" })
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Fixture Date" needVerity>
					<FleetDatePicker
						value={item?.fixtureDate ? dayjs(item?.fixtureDate) : null}
						onChange={(val) => {
							onChange?.("fixtureDate", val);
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					label="Cargo Name"
					needVerity
					value={item?.cargoName}
					onChange={(val: string) => {
						onChange?.("cargoName", val);
					}}
				/>
				<FleetFormItem needVerity label="Cargo Quantity">
					<div className="fixtureNotes--splitArea cargo-info">
						<FleetInput
							value={item?.cargoQuantity}
							type="number"
							onChange={(val: number) => {
								onChange?.("cargoQuantity", val);
							}}
						/>
						<FleetTabbar
							list={["MT", "CBM"]}
							value={item?.cargoUnit}
							onChange={(val: CargoUnitType) => {
								onChange?.("cargoUnit", val);
							}}
						/>
					</div>
				</FleetFormItem>
				<FleetFormItem needVerity label="Freight Rate">
					<div className="fixtureNotes--splitArea freight-info">
						<FleetInput
							value={item?.freightRate}
							type="number"
							onChange={(val: number) => {
								onChange?.("freightRate", val);
							}}
						/>
						<FleetTabbar
							list={["FR", "LP"]}
							value={item?.freightType}
							onChange={(val: FreightType) => {
								onChange?.("freightType", val);
							}}
						/>
					</div>
				</FleetFormItem>
			</div>
			<div className="fixtureNotes-container time-select">
				<FleetVerifyInput
					label="Add. Comm."
					type="number"
					value={item?.commission}
					onChange={(val: number) => {
						onChange?.("commission", val);
					}}
				/>
				<FleetVerifyInput
					label="Brokerage"
					type="number"
					value={item?.brokerage}
					onChange={(val: number) => {
						onChange?.("brokerage", val);
					}}
				/>
				<FleetFormItem label="Laycan From/To" needVerity>
					<FleetRangePicker
						allowClear
						showHour
						showMinute
						format="YYYY-MM-DD HH:mm"
						showTime={{
							defaultValue: [undefined, dayjs().hour(23).minute(59)]
						}}
						value={[
							item?.layCanFrom ? dayjs(item?.layCanFrom) : null,
							item?.layCanTo ? dayjs(item?.layCanTo) : null
						]}
						onChange={(vals: [Dayjs, Dayjs]) => {
							const ranges: Pick<HandoverFormFixtureNotesProps["item"], "layCanFrom" | "layCanTo"> =
								{
									layCanFrom: null,
									layCanTo: null
								};
							if (vals) {
								ranges.layCanFrom = vals?.[0]?.startOf("minutes")?.valueOf();
								ranges.layCanTo = vals?.[1]?.startOf("minutes")?.valueOf();
							}
							onCommit?.({ ...ranges });
						}}
					/>
				</FleetFormItem>
			</div>
			<div className="handoverForm--common--remarks">
				<div className="handoverForm--remarks--header">
					<span>Charterer Rating & C/P Special Clause</span>
					<FleetCustomRadio
						value={item?.tough}
						label="Tough"
						onChange={(val) => {
							onChange?.("tough", val);
						}}
					/>
					<FleetCustomRadio
						value={item?.vip}
						label="VIP"
						onChange={(val) => {
							onChange?.("vip", val);
						}}
					/>
				</div>
				<FleetTextarea
					placeholder=""
					className="handoverForm--remarks--textarea"
					value={item?.chartererRatingSpecialClaus}
					onChange={(val: string) => {
						onChange?.("chartererRatingSpecialClaus", val);
					}}
				/>
			</div>
			<HandoverFormLoadMore>
				<div className="fixtureNotes-container">
					<FleetVerifyInput
						label="First Freight Payment Rate %"
						value={item?.paymentRate}
						onChange={(val: number) => {
							onChange?.("paymentRate", val);
						}}
						min={0}
						max={100}
						type="number"
					/>
					<FleetVerifyInput
						label="Payment Term (Day)"
						type="number"
						value={item?.paymentTerm}
						onChange={(val: number) => {
							onChange?.("paymentTerm", val);
						}}
					/>
					<FleetFormItem label="Payment Start">
						<FleetDatePicker
							value={item?.paymentStartDay ? dayjs(item?.paymentStartDay) : null}
							onChange={(val) => {
								onChange?.("paymentStartDay", val);
							}}
						/>
					</FleetFormItem>
				</div>
			</HandoverFormLoadMore>
		</div>
	);
};

export default HandoverFormFixtureNotes;
