import dayjs, { Dayjs } from "dayjs";
import { RoutePortOtherIntervalsProps } from "./type";

export const disableDate = (mode: RoutePortOtherIntervalsProps["mode"], value: Dayjs) => {
	const today = dayjs();
	if (mode === 1) {
		return value?.isAfter(today.add(30, "day"), "day") || value?.isBefore(today, "day");
	}
	return value?.isBefore(today.subtract(90, "day"), "day") || value?.isAfter(today, "day");
};
