import RouteLegVesselIcon from "static/images/routeCommon/routeCommon_leg_vessel.png";
import RouteLegVesselSelectedIcon from "static/images/routeCommon/routeCommon_leg_vessel_selected.png";
import RouteLegVesselHoveredIcon from "static/images/routeCommon/routeCommon_leg_vessel_hovered.png";
import RouteAisVesselIcon from "static/images/routeCommon/routeCommon_ais_vessel.png";
import RouteAisVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_vessel_selected.png";
import RouteAisVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_vessel_hovered.png";
import RouteAreaVesselIcon from "static/images/routeCommon/routeCommon_area_vessel.png";
import RouteAreaVesselSelectedIcon from "static/images/routeCommon/routeCommon_area_vessel_selected.png";
import RouteAreaVesselHoveredIcon from "static/images/routeCommon/routeCommon_area_vessel_hovered.png";
import RoutePortVesselIcon from "static/images/routeCommon/routeCommon_port_vessel.png";
import RoutePortVesselSelectedIcon from "static/images/routeCommon/routeCommon_port_vessel_selected.png";
import RoutePortVesselHoveredIcon from "static/images/routeCommon/routeCommon_port_vessel_hovered.png";
import RouteVesselHoveredIcom from "static/images/routeCommon/routeCommon_vessel_hovered.png";
import RouteVesselTrackPointerIcon from "static/images/routeCommon/routeCommon_track_pointer.png";
import RouteVesselTrackInfoIcon from "static/images/routeCommon/routeCommon_track_info.png";
import RouteVesselTrackArrowIcon from "static/images/routeCommon/routeCommon_track_arrow.png";
import RouteDiscretePointerIcon from "static/images/routeCommon/routeCommon_discrete_pointer.png";
import RouteAisAquaGreenVesselIcon from "static/images/routeCommon/routeCommon_ais_aquagreen_vessel.png";
import RouteAisAquaGreenVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_aquagreen_vessel_selected.png";
import RouteAisBlueVesselIcon from "static/images/routeCommon/routeCommon_ais_blue_vessel.png";
import RouteAisBlueVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_blue_vessel_selected.png";
import RouteAisDarkGrayVesselIcon from "static/images/routeCommon/routeCommon_ais_darkgray_vessel.png";
import RouteAisDarkGrayVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_darkgray_vessel_selected.png";
import RouteAisForestGreenVesselIcon from "static/images/routeCommon/routeCommon_ais_forestgreen_vessel.png";
import RouteAisForestGreenVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_forestgreen_vessel_selected.png";
import RouteAisLavenderVesselIcon from "static/images/routeCommon/routeCommon_ais_lavender_vessel.png";
import RouteAisLavenderVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_lavender_vessel_selected.png";
import RouteAisLightOrangeVesselIcon from "static/images/routeCommon/routeCommon_ais_lightorange_vessel.png";
import RouteAisLightOrangeVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_lightorange_vessel_selected.png";
import RouteAisLimeGreenVesselIcon from "static/images/routeCommon/routeCommon_ais_limegreen_vessel.png.png";
import RouteAisLimeGreenVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_limegreen_vessel_selected.png";
import RouteAisOliveGreenVesselIcon from "static/images/routeCommon/routeCommon_ais_olivegreen_vessel.png";
import RouteAisOliveGreenVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_olivegreen_vessel_selected.png";
import RouteAisPinkVesselIcon from "static/images/routeCommon/routeCommon_ais_pink_vessel.png";
import RouteAisPinkVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_pink_vessel_selected.png";
import RouteAisPurpleVesselIcon from "static/images/routeCommon/routeCommon_ais_purple_vessel.png";
import RouteAisPurpleVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_purple_vessel_selected.png";
import RouteAisRedVesselIcon from "static/images/routeCommon/routeCommon_ais_red_vessel.png";
import RouteAisRedVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_red_vessel_selected.png";
import RouteAisSkyBlueVesselIcon from "static/images/routeCommon/routeCommon_ais_skyblue_vessel.png";
import RouteAisSkyBlueVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_skyblue_vessel_selected.png";
import RouteAisAquaGreenVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_aquagreen_vessel_hovered.png";

import RouteAisBlueVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_blue_vessel_hovered.png";

import RouteAisDarkGrayVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_darkgray_vessel_hovered.png";

import RouteAisForestGreenVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_forestgreen_vessel_hovered.png";

import RouteAisLavenderVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_lavender_vessel_hovered.png";

import RouteAisLightOrangeVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_lightorange_vessel_hovered.png";

import RouteAisLimeGreenVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_limegreen_vessel_hovered.png";

import RouteAisOliveGreenVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_olivegreen_vessel_hovered.png";

import RouteAisPinkVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_pink_vessel_hovered.png";

import RouteAisPurpleVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_purple_vessel_hovered.png";

import RouteAisRedVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_red_vessel_hovered.png";

import RouteAisSkyBlueVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_skyblue_vessel_hovered.png";

import RouteAisInfoIcon from "static/images/routeCommon/routeCommon_ais_info.png";

import { RouteCommonCurrentStateItemType } from "../../type";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";
import { convertToDms } from "@/tools/graph";

export const vesselTrackLayers = {
	leg: "leg-vesselTrack-layer",
	ais: "ais-vesselTrack-layer",
	area: "area-vesselTrack-layer",
	port: "port-vesselTrack-layer"
};

export const vesselTrackArrowLayers = {
	leg: "leg-vesselTrackArrow-layer",
	ais: "ais-vesselTrackArrow-layer",
	area: "area-vesselTrackArrow-layer",
	port: "port-vesselTrackArrow-layer"
};

export const vesselTrackPointerLayers = {
	leg: "leg-vesselTrackPointer-layer",
	ais: "ais-vesselTrackPointer-layer",
	area: "area-vesselTrackPointer-layer",
	port: "port-vesselTrackPointer-layer"
};

export const vesselTrackInfoLayers = {
	leg: "leg-vesselTrackInfo-layer",
	ais: "ais-vesselTrackInfo-layer",
	area: "area-vesselTrackInfo-layer",
	port: "port-vesselTrackInfo-layer"
};

export const vesselTrackSource = {
	leg: "leg-vesselTrack-source",
	ais: "ais-vesselTrack-source",
	area: "area-vesselTrack-source",
	port: "port-vesselTrack-source"
};

export const vesselTrackPointerSource = {
	leg: "leg-vesselTrackPointer-source",
	ais: "ais-vesselTrackPointer-source",
	area: "area-vesselTrackPointer-source",
	port: "port-vesselTrackPointer-source"
};

export const vesselGroupLayers = {
	leg: "leg-vesselGroup-layer",
	ais: "ais-vesselGroup-layer",
	area: "area-vesselGroup-layer",
	port: "port-vesselGroup-layer"
};

export const vesselGroupSource = {
	leg: "leg-vesselGroup-source",
	ais: "ais-vesselGroup-source",
	area: "area-vesselGroup-source",
	port: "port-vesselGroup-source"
};

export const currentVesselLayers = {
	leg: "leg-currentVessel-layer",
	ais: "ais-currentVessel-layer",
	area: "area-currentVessel-layer",
	port: "port-currentVessel-layer"
};

export const currentVesselSource = {
	leg: "leg-currentVessel-source",
	ais: "ais-currentVessel-source",
	area: "area-currentVessel-source",
	port: "port-currentVessel-source"
};

export const vesselIconNames: (
	| `${"ais" | "leg" | "area" | "port"}Vessel${"" | "Selected" | "Hoverd"}Icon`
	| "vesselHoveredIcon"
	| "vesselPointerIcon"
	| "vesselInfoIcon"
	| "vesselTrackArrowIcon"
	| "discretePointerIcon"
	| "routeAisAquaGreenVesselIcon"
	| "routeAisAquaGreenVesselSelectedIcon"
	| "routeAisBlueVesselIcon"
	| "routeAisBlueVesselSelectedIcon"
	| "routeAisDarkGrayVesselIcon"
	| "routeAisDarkGrayVesselSelectedIcon"
	| "routeAisForestGreenVesselIcon"
	| "routeAisForestGreenVesselSelectedIcon"
	| "routeAisLavenderVesselIcon"
	| "routeAisLavenderVesselSelectedIcon"
	| "routeAisLightOrangeVesselIcon"
	| "routeAisLightOrangeVesselSelectedIcon"
	| "routeAisLimeGreenVesselIcon"
	| "routeAisLimeGreenVesselSelectedIcon"
	| "routeAisOliveGreenVesselIcon"
	| "routeAisOliveGreenVesselSelectedIcon"
	| "routeAisPinkVesselIcon"
	| "routeAisPinkVesselSelectedIcon"
	| "routeAisPurpleVesselIcon"
	| "routeAisPurpleVesselSelectedIcon"
	| "routeAisRedVesselIcon"
	| "routeAisRedVesselSelectedIcon"
	| "routeAisSkyBlueVesselIcon"
	| "routeAisSkyBlueVesselSelectedIcon"
	| "routeAisAquaGreenVesselHoveredIcon"
	| "routeAisBlueVesselHoveredIcon"
	| "routeAisDarkGrayVesselHoveredIcon"
	| "routeAisForestGreenVesselHoveredIcon"
	| "routeAisLavenderVesselHoveredIcon"
	| "routeAisLightOrangeVesselHoveredIcon"
	| "routeAisLimeGreenVesselHoveredIcon"
	| "routeAisOliveGreenVesselHoveredIcon"
	| "routeAisPinkVesselHoveredIcon"
	| "routeAisPurpleVesselHoveredIcon"
	| "routeAisRedVesselHoveredIcon"
	| "routeAisSkyBlueVesselHoveredIcon"
	| "routeAisInfoIcon"
)[] = [
	"vesselHoveredIcon",
	"legVesselIcon",
	"legVesselSelectedIcon",
	"legVesselHoverdIcon",
	"aisVesselIcon",
	"aisVesselSelectedIcon",
	"aisVesselHoverdIcon",
	"areaVesselHoverdIcon",
	"areaVesselIcon",
	"areaVesselSelectedIcon",
	"portVesselHoverdIcon",
	"portVesselIcon",
	"portVesselSelectedIcon",
	"vesselPointerIcon",
	"vesselInfoIcon",
	"vesselTrackArrowIcon",
	"discretePointerIcon",
	"routeAisAquaGreenVesselIcon",
	"routeAisAquaGreenVesselSelectedIcon",
	"routeAisBlueVesselIcon",
	"routeAisBlueVesselSelectedIcon",
	"routeAisDarkGrayVesselIcon",
	"routeAisDarkGrayVesselSelectedIcon",
	"routeAisForestGreenVesselIcon",
	"routeAisForestGreenVesselSelectedIcon",
	"routeAisLavenderVesselIcon",
	"routeAisLavenderVesselSelectedIcon",
	"routeAisLightOrangeVesselIcon",
	"routeAisLightOrangeVesselSelectedIcon",
	"routeAisLimeGreenVesselIcon",
	"routeAisLimeGreenVesselSelectedIcon",
	"routeAisOliveGreenVesselIcon",
	"routeAisOliveGreenVesselSelectedIcon",
	"routeAisPinkVesselIcon",
	"routeAisPinkVesselSelectedIcon",
	"routeAisPurpleVesselIcon",
	"routeAisPurpleVesselSelectedIcon",
	"routeAisRedVesselIcon",
	"routeAisRedVesselSelectedIcon",
	"routeAisSkyBlueVesselIcon",
	"routeAisSkyBlueVesselSelectedIcon",
	"routeAisAquaGreenVesselHoveredIcon",
	"routeAisBlueVesselHoveredIcon",
	"routeAisDarkGrayVesselHoveredIcon",
	"routeAisForestGreenVesselHoveredIcon",
	"routeAisLavenderVesselHoveredIcon",
	"routeAisLightOrangeVesselHoveredIcon",
	"routeAisLimeGreenVesselHoveredIcon",
	"routeAisOliveGreenVesselHoveredIcon",
	"routeAisPinkVesselHoveredIcon",
	"routeAisPurpleVesselHoveredIcon",
	"routeAisRedVesselHoveredIcon",
	"routeAisSkyBlueVesselHoveredIcon",
	"routeAisInfoIcon"
];

export const routeCommonVesselIconMap: Record<
	| `${"ais" | "leg" | "area" | "port"}Vessel${"" | "Selected" | "Hoverd"}Icon`
	| "vesselHoveredIcon"
	| "vesselPointerIcon"
	| "vesselInfoIcon"
	| "vesselTrackArrowIcon"
	| "discretePointerIcon"
	| "routeAisAquaGreenVesselIcon"
	| "routeAisAquaGreenVesselSelectedIcon"
	| "routeAisBlueVesselIcon"
	| "routeAisBlueVesselSelectedIcon"
	| "routeAisDarkGrayVesselIcon"
	| "routeAisDarkGrayVesselSelectedIcon"
	| "routeAisForestGreenVesselIcon"
	| "routeAisForestGreenVesselSelectedIcon"
	| "routeAisLavenderVesselIcon"
	| "routeAisLavenderVesselSelectedIcon"
	| "routeAisLightOrangeVesselIcon"
	| "routeAisLightOrangeVesselSelectedIcon"
	| "routeAisLimeGreenVesselIcon"
	| "routeAisLimeGreenVesselSelectedIcon"
	| "routeAisOliveGreenVesselIcon"
	| "routeAisOliveGreenVesselSelectedIcon"
	| "routeAisPinkVesselIcon"
	| "routeAisPinkVesselSelectedIcon"
	| "routeAisPurpleVesselIcon"
	| "routeAisPurpleVesselSelectedIcon"
	| "routeAisRedVesselIcon"
	| "routeAisRedVesselSelectedIcon"
	| "routeAisSkyBlueVesselIcon"
	| "routeAisSkyBlueVesselSelectedIcon"
	| "routeAisAquaGreenVesselHoveredIcon"
	| "routeAisBlueVesselHoveredIcon"
	| "routeAisDarkGrayVesselHoveredIcon"
	| "routeAisForestGreenVesselHoveredIcon"
	| "routeAisLavenderVesselHoveredIcon"
	| "routeAisLightOrangeVesselHoveredIcon"
	| "routeAisLimeGreenVesselHoveredIcon"
	| "routeAisOliveGreenVesselHoveredIcon"
	| "routeAisPinkVesselHoveredIcon"
	| "routeAisPurpleVesselHoveredIcon"
	| "routeAisRedVesselHoveredIcon"
	| "routeAisSkyBlueVesselHoveredIcon"
	| "routeAisInfoIcon",
	string
> = {
	legVesselIcon: RouteLegVesselIcon,
	legVesselSelectedIcon: RouteLegVesselSelectedIcon,
	// vmVesselHoveredIcon: RouteLegVesselHoveredIcon,
	aisVesselIcon: RouteAisVesselIcon,
	aisVesselSelectedIcon: RouteAisVesselSelectedIcon,
	// aisVesselHoveredIcon: RouteAisVesselHoveredIcon,
	areaVesselIcon: RouteAreaVesselIcon,
	areaVesselSelectedIcon: RouteAreaVesselSelectedIcon,
	// areaVesselHoveredIcon: RouteAreaVesselHoveredIcon,
	portVesselIcon: RoutePortVesselIcon,
	portVesselSelectedIcon: RoutePortVesselSelectedIcon,
	// portVesselHoveredIcon: RoutePortVesselHoveredIcon,
	vesselHoveredIcon: RouteVesselHoveredIcom,
	vesselPointerIcon: RouteVesselTrackPointerIcon,
	vesselInfoIcon: RouteVesselTrackInfoIcon,
	vesselTrackArrowIcon: RouteVesselTrackArrowIcon,
	aisVesselHoverdIcon: RouteAisVesselHoveredIcon,
	legVesselHoverdIcon: RouteLegVesselHoveredIcon,
	areaVesselHoverdIcon: RouteAreaVesselHoveredIcon,
	portVesselHoverdIcon: RoutePortVesselHoveredIcon,
	discretePointerIcon: RouteDiscretePointerIcon,
	routeAisAquaGreenVesselIcon: RouteAisAquaGreenVesselIcon,
	routeAisAquaGreenVesselSelectedIcon: RouteAisAquaGreenVesselSelectedIcon,
	routeAisBlueVesselIcon: RouteAisBlueVesselIcon,
	routeAisBlueVesselSelectedIcon: RouteAisBlueVesselSelectedIcon,
	routeAisDarkGrayVesselIcon: RouteAisDarkGrayVesselIcon,
	routeAisDarkGrayVesselSelectedIcon: RouteAisDarkGrayVesselSelectedIcon,
	routeAisForestGreenVesselIcon: RouteAisForestGreenVesselIcon,
	routeAisForestGreenVesselSelectedIcon: RouteAisForestGreenVesselSelectedIcon,
	routeAisLavenderVesselIcon: RouteAisLavenderVesselIcon,
	routeAisLavenderVesselSelectedIcon: RouteAisLavenderVesselSelectedIcon,
	routeAisLightOrangeVesselIcon: RouteAisLightOrangeVesselIcon,
	routeAisLightOrangeVesselSelectedIcon: RouteAisLightOrangeVesselSelectedIcon,
	routeAisLimeGreenVesselIcon: RouteAisLimeGreenVesselIcon,
	routeAisLimeGreenVesselSelectedIcon: RouteAisLimeGreenVesselSelectedIcon,
	routeAisOliveGreenVesselIcon: RouteAisOliveGreenVesselIcon,
	routeAisOliveGreenVesselSelectedIcon: RouteAisOliveGreenVesselSelectedIcon,
	routeAisPinkVesselIcon: RouteAisPinkVesselIcon,
	routeAisPinkVesselSelectedIcon: RouteAisPinkVesselSelectedIcon,
	routeAisPurpleVesselIcon: RouteAisPurpleVesselIcon,
	routeAisPurpleVesselSelectedIcon: RouteAisPurpleVesselSelectedIcon,
	routeAisRedVesselIcon: RouteAisRedVesselIcon,
	routeAisRedVesselSelectedIcon: RouteAisRedVesselSelectedIcon,
	routeAisSkyBlueVesselIcon: RouteAisSkyBlueVesselIcon,
	routeAisSkyBlueVesselSelectedIcon: RouteAisSkyBlueVesselSelectedIcon,
	routeAisAquaGreenVesselHoveredIcon: RouteAisAquaGreenVesselHoveredIcon,
	routeAisBlueVesselHoveredIcon: RouteAisBlueVesselHoveredIcon,
	routeAisDarkGrayVesselHoveredIcon: RouteAisDarkGrayVesselHoveredIcon,
	routeAisForestGreenVesselHoveredIcon: RouteAisForestGreenVesselHoveredIcon,
	routeAisLavenderVesselHoveredIcon: RouteAisLavenderVesselHoveredIcon,
	routeAisLightOrangeVesselHoveredIcon: RouteAisLightOrangeVesselHoveredIcon,
	routeAisLimeGreenVesselHoveredIcon: RouteAisLimeGreenVesselHoveredIcon,
	routeAisOliveGreenVesselHoveredIcon: RouteAisOliveGreenVesselHoveredIcon,
	routeAisPinkVesselHoveredIcon: RouteAisPinkVesselHoveredIcon,
	routeAisPurpleVesselHoveredIcon: RouteAisPurpleVesselHoveredIcon,
	routeAisRedVesselHoveredIcon: RouteAisRedVesselHoveredIcon,
	routeAisSkyBlueVesselHoveredIcon: RouteAisSkyBlueVesselHoveredIcon,
	routeAisInfoIcon: RouteAisInfoIcon
};
// {
// 	currentState: RouteCommonCurrentStateItemType;
// }
export const initialVesselGroupData = <T extends { currentState: RouteCommonCurrentStateItemType }>(
	item: T
): GeoJSON.Feature<GeoJSON.Point, T> => {
	return {
		type: "Feature",
		geometry: {
			type: "Point",
			coordinates: [item?.currentState?.lon, item?.currentState?.lat]
		},
		properties: {
			...item,
			course: item?.currentState?.course
		}
	};
};

export const initialAisInfoGroupData = <
	T extends { currentState: RouteCommonCurrentStateItemType }
>(
	item: T
): GeoJSON.Feature<GeoJSON.Point, T["currentState"]> => {
	return {
		type: "Feature",
		geometry: {
			type: "Point",
			coordinates: [item?.currentState?.lon, item?.currentState?.lat]
		},
		properties: {
			...item?.currentState,
			vesselName: item?.currentState?.vesselName?.slice(0, 14)
		}
	};
};

export const coordinatesControll: mapboxgl.IControl & {
	updateCoordinates: (event: MapMouseEvent) => void;
	currentElement: HTMLDivElement;
} = {
	currentElement: null,
	onAdd: function (map) {
		const coordinatesElement = document.createElement("div");
		coordinatesElement.style.cssText = `
				position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        font-weight: 2000;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;
        font-size: 6px`;
		this.currentElement = coordinatesElement;
		map.on("mousemove", this.updateCoordinates?.bind(this));
		return coordinatesElement;
	},
	onRemove: function (map) {
		map.off("mousemove", this.updateCoordinates?.bind(this));
		this.currentElement.parentNode.removeChild(this.currentElement);
		this.currentElement = null;
	},
	updateCoordinates: function (event) {
		if (!event) return;
		const lngLat = event.lngLat;
		console.log("this", this.currentElement);
		this.currentElement.textContent = `Lat/Lng: ${convertToDms(lngLat.lat, lngLat.lng)}`;
	}
};
