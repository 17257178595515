import { MutableRefObject } from "react";
import { addSourceToMap } from "../utils";
import { RouteCommonAisItemType } from "../../list";
import { initialAisInfoGroupData, initialVesselGroupData } from "../source";
import { GeoJSONSource } from "mapbox-gl";

const aisSourceMap = {
	"ais-info-source": "ais-info-layer"
};

const useAisLayer = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadSource = () => {
		addSourceToMap(mapboxGl.current, {
			sourceName: "ais-info-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
	};

	const loadLayers = () => {
		mapboxGl.current.addLayer({
			id: aisSourceMap["ais-info-source"],
			type: "symbol",
			source: "ais-info-source",
			layout: {
				// 'symbol-placement': 'line',
				"symbol-spacing": 100, // 图标间隔，默认为250
				"icon-image": "routeAisInfoIcon", //箭头图标
				"icon-size": 1,
				"text-field": ["get", "vesselName"],
				"icon-offset": [-75, -20],
				"text-offset": [-8.5, -2.8],
				"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
				// 文字大小
				// "text-font": ["Microsoft YaHei"],
				"text-size": 11,
				"icon-allow-overlap": true
			},
			paint: {
				"text-color": "#fff"
			}
		});
	};

	const handleAisInfoLayerLoad = (items: RouteCommonAisItemType[]) => {
		const geoJson: GeoJSON.Feature<GeoJSON.Point, RouteCommonAisItemType["currentState"]>[] =
			items?.map((item) => initialAisInfoGroupData(item));

		const currentSource = mapboxGl?.current?.getSource("ais-info-source") as GeoJSONSource;
		console.log("handleAisInfoLayerLoad", geoJson, items);
		currentSource?.setData({
			type: "FeatureCollection",
			features: geoJson
		});
	};

	return {
		loadSource,
		loadLayers,
		handleAisInfoLayerLoad
	};
};

export default useAisLayer;
