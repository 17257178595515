import { useEffect, useState } from "react";
import useOtherIndex from "./useOtherIndex";
import { useBunkerClauseIndex, useBunkerCoseIndex, useBunkerDetailsIndex } from "../components";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { MovementBunkerPlanProps } from "../type";
import { onRequest } from "@/action";

const useIndex = (
	voyageId: MovementBunkerPlanProps["voyageId"],
	hide: boolean,
	onConfirm: MovementBunkerPlanProps["onConfirm"],
	onClose?: () => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);

	const {
		dataSource: bunkerPlan,
		change: handleBunkerPlanChange,
		init: handleBunkerPlanInit
	} = useOtherIndex();

	console.log("bunkerPlan", bunkerPlan);

	const {
		dataSource: bunkerClause,
		change: handleBunkerClauseChange,
		commit: handleBunkerClauseCommit,
		init: handleBunkerClauseInit
	} = useBunkerClauseIndex();

	const {
		summary,
		dataSource: bunkerDetails,
		change: handleBunkerDetailsChange,
		commit: handleBunkerDetailsCommit,
		init: handleBunkerDetailsInit
	} = useBunkerDetailsIndex();

	const {
		summary: bunkerCostSummary,
		hsfoDataSource,
		dataSource: bunkerCost,
		change: handleBunkerCostChange,
		commit: handleBunkerCostCommit,
		init: handleBunkerCostInit,
		handleBorPriceCommit,
		handleCalcTotalCost
	} = useBunkerCoseIndex({
		borAbout: bunkerClause?.borAbout,
		borPrice: bunkerClause?.borPrice,
		scrubberBenefit: bunkerPlan?.scrubberBenefit,
		scrubberPrice: bunkerPlan?.scrubberPrice
	});

	const getMovementBunkerCostFront = () => {
		setLoading(true);
	};

	const getMovementBunkerCostSuccess = ({ data }) => {
		setLoading(false);
		handleBunkerClauseInit(data?.bunkerCostDetails);
		handleBunkerDetailsInit(data?.bunkerPortCost);
		handleBunkerPlanInit(data?.bunkerPlan);
		handleBunkerCostInit(data?.dataList);
	};

	const getMovementBunkerCostFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getMovementBunkerCost = () => {
		onRequest(
			"getMovementBunkerCostApi",
			{ voyageId },
			getMovementBunkerCostFront,
			getMovementBunkerCostSuccess,
			getMovementBunkerCostFailed,
			dispatch,
			navigate
		);
	};

	const saveMovementBunkerCost = () => {
		const totalCost = handleCalcTotalCost();
		onRequest(
			"saveMovementBunkerCostApi",
			{
				bunkerPlan: {
					...bunkerPlan,
					...totalCost
				},
				bunkerCostDetails: bunkerClause,
				dataList: bunkerCost,
				bunkerPortCost: bunkerDetails
			},
			getMovementBunkerCostFront,
			(response) => {
				const deliveryBrobs: Parameters<MovementBunkerPlanProps["onConfirm"]>[1]["delivery"] = {
					hsfoBrob: bunkerCost?.[0]?.bodQuantity ?? "",
					lsfoBrob: bunkerCost?.[1]?.bodQuantity ?? "",
					mdoBrob: bunkerCost?.[2]?.bodQuantity ?? "",
					mgoBrob: bunkerCost?.[3]?.bodQuantity ?? ""
				};
				const rediliveryBrobs: Parameters<MovementBunkerPlanProps["onConfirm"]>[1]["redilivery"] = {
					hsfoBrob: bunkerCost?.[0]?.borQuantity ?? "",
					lsfoBrob: bunkerCost?.[1]?.borQuantity ?? "",
					mdoBrob: bunkerCost?.[2]?.borQuantity ?? "",
					mgoBrob: bunkerCost?.[3]?.borQuantity ?? ""
				};
				setLoading(false);
				onConfirm?.(
					bunkerDetails,
					{ delivery: deliveryBrobs, redilivery: rediliveryBrobs },
					{ ...totalCost, totalBunkerPlanExpense: summary }
				);
				onClose?.();
			},
			getMovementBunkerCostFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) return;
		if (!voyageId) return;
		getMovementBunkerCost();
	}, [voyageId, hide]);

	return {
		loading,
		hsfoDataSource,
		bunkerClause,
		bunkerDetails,
		handleBunkerClauseChange,
		handleBunkerClauseCommit,
		handleBunkerDetailsChange,
		handleBunkerDetailsCommit,
		bunkerPlan,
		handleBunkerPlanChange,
		bunkerCost,
		bunkerCostSummary,
		handleBunkerCostChange,
		handleBunkerCostCommit,
		handleBorPriceCommit,
		saveMovementBunkerCost
	};
};

export default useIndex;
