import { type ApiParameter } from "@/api";
import request from "@/service";
import {
	getMovementGroupParams,
	getMovementParams,
	getMovementTaskGroupParams,
	updateMovementTaskParams,
	getMovementCommentGroupParams,
	deleteMovementCommentParams,
	saveMovementCommentParams,
	getMovementOtherExpenseParams,
	updateMovementOtherExpensesParams,
	saveMovementBunkerPlanParams,
	getMovementStowagePlanParams,
	saveMovementStowagePlanParams,
	getMovementLaytimeParams,
	getMovementLaytimeGroupParams,
	saveMovementLaytimeGroupParams,
	deleteMovementLaytimeParams,
	saveMovementDetailParams,
	editMovementVoyageStatusParams,
	deleteMovementParams,
	getMovementBunkerPlanParams,
	getMovementFilePathParams,
	setMovementMainPlanParams,
	updateMovementPlanPayload,
	getMovementPlanDetailsParmas,
	getMovementTctcParams
} from "./type";

const enum ApiMap {
	getMovementGroupParameter,
	deleteMovementParameter,
	getMovementParameter,
	getMovementTaskGroupParameter,
	updateMovementTaskParameter,
	getMovementCommentGroupParameter,
	deleteMovementCommentParameter,
	saveMovementCommentParameter,
	getMovementOtherExpensesParameter,
	updateMovementOtherExpensesParameter,
	getMovementBunkerPlanParameter,
	saveMovementBunkerPlanParameter,
	getMovementStowagePlanParameter,
	saveMovementStowagePlanParameter,
	getMovementLaytimeGroupParameter,
	getMovementLaytimeParameter,
	saveMovementLaytimeGroupParameter,
	deleteMovementLaytimeParameter,
	saveMovementDetailParameter,
	editMovementVoyageStatusParameter,
	getMovementFilePathParameter,
	setMovementMainPlanParameter,
	updateMovementPlanParameter,
	getMovementPlanDetailsParmeter,
	getMovementTctcParameter
}

type ApiUrl = keyof typeof ApiMap;

export const appVersion =
	process.env.NODE_ENV === "development" ? "/gateway/voyage/v1" : "/gateway/voyage/v1";

const movementApi: ApiParameter<ApiUrl> = {
	getMovementGroupParameter: {
		method: "get",
		url: appVersion + "/move/voyage/query"
	},
	deleteMovementParameter: {
		method: "delete",
		url: appVersion + "/move/voyage"
	},
	getMovementParameter: {
		method: "get",
		url: appVersion + "/move/voyage"
	},
	getMovementTaskGroupParameter: {
		method: "get",
		url: appVersion + "/move/task/query"
	},
	updateMovementTaskParameter: {
		method: "put",
		url: appVersion + "/move/task"
	},
	getMovementCommentGroupParameter: {
		method: "get",
		url: appVersion + "/move/comment"
	},
	deleteMovementCommentParameter: {
		method: "delete",
		url: appVersion + "/move/comment"
	},
	saveMovementCommentParameter: {
		method: "post",
		url: appVersion + "/move/comment"
	},
	getMovementOtherExpensesParameter: {
		method: "get",
		url: appVersion + "/move/dialog/other/voyage"
	},
	updateMovementOtherExpensesParameter: {
		method: "post",
		url: appVersion + "/move/dialog/other"
	},
	getMovementBunkerPlanParameter: {
		method: "get",
		url: appVersion + "/move/dialog/bunker"
	},
	saveMovementBunkerPlanParameter: {
		method: "put",
		url: appVersion + "/move/dialog/bunker"
	},
	getMovementStowagePlanParameter: {
		method: "get",
		url: appVersion + "/move/dialog/stowage"
	},
	saveMovementStowagePlanParameter: {
		method: "put",
		url: appVersion + "/move/dialog/stowage"
	},
	getMovementLaytimeGroupParameter: {
		method: "get",
		url: appVersion + "/move/dialog/laytime/voyage"
	},
	getMovementLaytimeParameter: {
		method: "get",
		url: appVersion + "/move/dialog/laytime/port"
	},
	saveMovementLaytimeGroupParameter: {
		method: "post",
		url: appVersion + "/move/dialog/laytime"
	},
	deleteMovementLaytimeParameter: {
		method: "delete",
		url: appVersion + "/move/dialog"
	},
	saveMovementDetailParameter: {
		method: "put",
		url: appVersion + "/move/voyage"
	},
	editMovementVoyageStatusParameter: {
		method: "put",
		url: appVersion + "/move/voyage/status"
	},
	getMovementFilePathParameter: {
		method: "get",
		url: appVersion + "/move/voyage/url"
	},
	setMovementMainPlanParameter: {
		method: "put",
		url: appVersion + "/move/voyage/plan/main"
	},
	updateMovementPlanParameter: {
		method: "post",
		url: appVersion + "/est/vc/plan"
	},
	getMovementPlanDetailsParmeter: {
		method: "get",
		url: appVersion + "/pre/voyage/plan"
	},
	getMovementTctcParameter: {
		method: "get",
		url: appVersion + "/move/voyage"
	}
};

export const getMovementGroupApi = (params: getMovementGroupParams) => {
	console.log("getMovementGroupApi", movementApi.getMovementGroupParameter, params);
	return request.request({
		...movementApi.getMovementGroupParameter,
		params
	});
};

export const deleteMovementApi = (params: deleteMovementParams) => {
	return request.request({
		...movementApi.deleteMovementParameter,
		params
	});
};

export const getMovementApi = (params: getMovementParams) => {
	return request.request({
		...movementApi.getMovementParameter,
		url: `${movementApi.getMovementParameter.url}/${params?.id}`
	});
};

export const getMovementTaskGroupApi = (params: getMovementTaskGroupParams) => {
	return request.request({
		...movementApi.getMovementTaskGroupParameter,
		params
	});
};

export const updateMovementTaskApi = (params: updateMovementTaskParams) => {
	return request.request({
		...movementApi.updateMovementTaskParameter,
		data: params
	});
};

export const getMovementCommentGroupApi = (params: getMovementCommentGroupParams) => {
	return request.request({
		...movementApi.getMovementCommentGroupParameter,
		url: `${movementApi.getMovementCommentGroupParameter.url}/${params?.id}`
	});
};

export const deleteMovementCommentApi = (params: deleteMovementCommentParams) => {
	return request.request({
		...movementApi.deleteMovementCommentParameter,
		url: `${movementApi.deleteMovementCommentParameter.url}/${params?.id}`
	});
};

export const saveMovementCommentApi = (params: saveMovementCommentParams) => {
	return request.request({
		...movementApi.saveMovementCommentParameter,
		data: params
	});
};

export const getMovementOtherExpenseApi = (params: getMovementOtherExpenseParams) => {
	return request.request({
		...movementApi.getMovementOtherExpensesParameter,
		url: `${movementApi.getMovementOtherExpensesParameter.url}/${params?.id}`
	});
};

export const updateMovementOtherExpensesApi = (params: updateMovementOtherExpensesParams) => {
	return request.request({
		...movementApi.updateMovementOtherExpensesParameter,
		data: params
	});
};

export const getMovementBunkerPlanApi = (params: getMovementBunkerPlanParams) => {
	return request.request({
		...movementApi.getMovementBunkerPlanParameter,
		url: `${movementApi.getMovementBunkerPlanParameter.url}/${params?.id}`
	});
};

export const saveMovementBunkerPlanApi = (params: saveMovementBunkerPlanParams) => {
	return request.request({
		...movementApi.saveMovementBunkerPlanParameter,
		data: params
	});
};

export const getMovementStowagePlanApi = (params: getMovementStowagePlanParams) => {
	return request.request({
		...movementApi.getMovementStowagePlanParameter,
		url: `${movementApi.getMovementStowagePlanParameter.url}/${params?.id}`
	});
};

export const saveMovementStowagePlanApi = (params: saveMovementStowagePlanParams) => {
	return request.request({
		...movementApi.saveMovementStowagePlanParameter,
		data: params
	});
};

export const getMovementLaytimeGroupApi = (params: getMovementLaytimeGroupParams) => {
	return request.request({
		...movementApi.getMovementLaytimeGroupParameter,
		url: `${movementApi.getMovementLaytimeGroupParameter.url}/${params?.id}`
	});
};

export const getMovementLaytimeApi = (params: getMovementLaytimeParams) => {
	return request.request({
		...movementApi.getMovementLaytimeParameter,
		url: `${movementApi.getMovementLaytimeParameter.url}/${params?.id}`
	});
};

export const saveMovementLaytimeGroupApi = (params: saveMovementLaytimeGroupParams) => {
	return request.request({
		...movementApi.saveMovementLaytimeGroupParameter,
		data: params
	});
};

export const deleteMovementLaytimeApi = (params: deleteMovementLaytimeParams) => {
	return request.request({
		...movementApi.deleteMovementLaytimeParameter,
		url: `${movementApi.deleteMovementLaytimeParameter.url}/${params?.id}`
	});
};

export const saveMovementDetailApi = (params: saveMovementDetailParams) => {
	return request.request({
		...movementApi.saveMovementDetailParameter,
		data: params
	});
};

export const editMovementVoyageStatusApi = (params: editMovementVoyageStatusParams) => {
	return request.request({
		...movementApi.editMovementVoyageStatusParameter,
		data: params
	});
};

export const getMovementFilePathApi = (params: getMovementFilePathParams) => {
	return request.request({
		...movementApi.getMovementFilePathParameter,
		params
	});
};

export const setMovementMainPlanApi = (params: setMovementMainPlanParams) => {
	return request.request({
		...movementApi.setMovementMainPlanParameter,
		params
	});
};

export const updateMovementPlanApi = (params: updateMovementPlanPayload) => {
	return request.request({
		...movementApi.updateMovementPlanParameter,
		method: params?.method,
		data: params?.params
	});
};

export const getMovementPlanDetailsApi = (params: getMovementPlanDetailsParmas) => {
	return request.request({
		...movementApi.getMovementPlanDetailsParmeter,
		url: `${movementApi.getMovementPlanDetailsParmeter.url}/${params?.voyageId}`
	});
};

export const getMovementTctcApi = (params: getMovementTctcParams) => {
	return request.request({
		...movementApi.getMovementTctcParameter,
		url: `${movementApi.getMovementTctcParameter.url}/${params?.id}`
	});
};
