import { RequestApiMapType } from "..";
import {
	RouteCommonVesselCardParamsType,
	RouteCommonVesselCardApiType,
	vesselCardApiMap
} from "./vesselCard";
import { RouteCommonAisDataParamsType, aisDataApiMap, RouteCommonAisDataApiType } from "./aisData";
import { RouteCommonAreaParamsType, routeCommonAreaApiMap, RouteCommonAreaApiType } from "./area";
import { commonApiMap, RouteCommonOtherApiType, RouteCommonOtherParamsType } from "./common";
import {
	RouteCommonAreaVesselParamsType,
	areaVesselApiMap,
	RouteCommonAreaVesselApiType
} from "./areaVessel";
import {
	portCallApiMap,
	RouteCommonPortCallListParamsType,
	RouteCommonPortCallListApiType
} from "./portCall";
import {
	portVesselApiMap,
	RouteCommonPortVesselApiType,
	RouteCommonPortVesselParamsType
} from "./portVessel";
import { aisApiMap, RouteCommonAisApiType, RouteCommonAisParamsType } from "./ais";
import { LegApiMap, RouteCommonLegListParamsType, RouteCommonLegListApiType } from "./leg";
import {
	meteorologicalApiMap,
	RouteCommonMeteorologicalApiType,
	RouteCommonMeteorologicalParamsType
} from "./meteorological";

export type RouteCommonApiType =
	| RouteCommonVesselCardApiType
	| RouteCommonAisDataApiType
	| RouteCommonAreaApiType
	| RouteCommonOtherApiType
	| RouteCommonAreaVesselApiType
	| RouteCommonPortCallListApiType
	| RouteCommonPortVesselApiType
	| RouteCommonLegListApiType
	| RouteCommonAisApiType
	| RouteCommonMeteorologicalApiType;

export type RouteCommonParamsType =
	| RouteCommonVesselCardParamsType
	| RouteCommonAisDataParamsType
	| RouteCommonAreaParamsType
	| RouteCommonOtherParamsType
	| RouteCommonAreaVesselParamsType
	| RouteCommonPortCallListParamsType
	| RouteCommonPortVesselParamsType
	| RouteCommonLegListParamsType
	| RouteCommonAisParamsType
	| RouteCommonMeteorologicalParamsType;

export const routeCommonApiMap: RequestApiMapType<RouteCommonApiType, RouteCommonParamsType> = {
	...vesselCardApiMap,
	...aisDataApiMap,
	...routeCommonAreaApiMap,
	...commonApiMap,
	...areaVesselApiMap,
	...portCallApiMap,
	...portVesselApiMap,
	...LegApiMap,
	...aisApiMap,
	...meteorologicalApiMap
};
