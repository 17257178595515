import React from "react";
import style from "./index.module.less";
import { CommonColumnCategoryProps } from "./type";
import { Button } from "@/_components";

export default function CommonColumnCategory<T extends string, U extends string>(
	props: CommonColumnCategoryProps<T, U>
) {
	return (
		<div className={style["common-category"]}>
			<div className="category-header">
				<span>Select Columns To Display</span>
				<Button size="small">Reset Column</Button>
			</div>
			<div className="category-container"></div>
		</div>
	);
}
