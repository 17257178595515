import { LayoutChildren } from "@/layout/router";
import { PageRouterProps } from "@/router";

export const findRouter = (pathname: string, menus: PageRouterProps[] = []) => {
	let flag = false;
	console.log("me", menus);
	debugger;
	for (let menu of menus) {
		switch (true) {
			case pathname === "/" && menu.key === "/layout/estimation":
			case menu.key === pathname:
				return true;
			case menu.children?.length > 0:
				flag = findRouter(pathname, menu.children);
				if (flag) return true;
				break;
			default:
				break;
		}
	}

	console.log("flag", flag);

	return flag;
};

const handlerChildMenus = (menus: PageRouterProps[], parentKey: string | number) => {
	return menus?.map((item, idx, arr) => {
		const isMovement = arr?.findIndex(
			(route) => route?.key === "/layout/voyagemanage/movement/list"
		);
		const { index, path, key, ...rest } = item;
		const route: PageRouterProps = { ...rest };
		route.key = key;
		switch (true) {
			case isMovement !== -1 && key === "/layout/voyagemanage/movement/list":
				route.index = true;
				route.key = parentKey;
				break;
			case isMovement === -1 && idx === 0:
				route.index = true;
				route.key = parentKey;
				break;
			default:
				route.path = path;
				break;
		}
		return route;
	});
};

export const flatMenus = (menus: PageRouterProps[]) => {
	const newMenus: PageRouterProps[] = [];
	menus.map((item) => {
		if (!item.children || item?.children?.length === 0) {
			newMenus.push(item);
		} else {
			newMenus.push(item);
			newMenus.push(...flatMenus(item.children));
		}
	});

	return newMenus;
};

export const findRouterElement = (key: string | number) => {
	const newMenus = flatMenus(LayoutChildren);
	console.log("newroutew", newMenus, key);
	return newMenus?.find((item) => item?.key === key)?.element;
};

export const handlerMenus = (menus: PageRouterProps[]) => {
	const newMenus: PageRouterProps[] = [];
	for (let i = 0; i < menus?.length; i++) {
		switch (true) {
			case !menus[i]?.children || menus[i]?.children?.length <= 0:
				newMenus?.push({
					...menus[i],
					component: null
				});
				break;
			case menus[i]?.children?.length > 0:
				newMenus?.push({
					...menus[i],
					component: null,
					children: handlerChildMenus(menus[i].children, menus?.[i]?.key)
				});
				break;
			default:
				break;
		}
	}
	return newMenus;
};
