import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
	getMovementGroupApi,
	getMovementGroupParams,
	getMovementApi,
	getMovementParams,
	deleteMovementApi,
	deleteMovementParams,
	getMovementCommentGroupApi,
	getMovementCommentGroupParams,
	getMovementTaskGroupApi,
	getMovementTaskGroupParams,
	deleteMovementCommentApi,
	deleteMovementCommentParams,
	saveMovementCommentApi,
	saveMovementCommentParams,
	updateMovementTaskApi,
	updateMovementTaskParams,
	updateMovementOtherExpensesApi,
	updateMovementOtherExpensesParams,
	getMovementBunkerPlanApi,
	getMovementBunkerPlanParams,
	saveMovementBunkerPlanApi,
	saveMovementBunkerPlanParams,
	getMovementStowagePlanApi,
	getMovementStowagePlanParams,
	saveMovementStowagePlanApi,
	saveMovementStowagePlanParams,
	getMovementLaytimeApi,
	getMovementLaytimeGroupApi,
	getMovementLaytimeGroupParams,
	getMovementLaytimeParams,
	deleteMovementLaytimeApi,
	deleteMovementLaytimeParams,
	saveMovementLaytimeGroupApi,
	saveMovementLaytimeGroupParams,
	saveMovementDetailApi,
	saveMovementDetailParams,
	editMovementVoyageStatusApi,
	editMovementVoyageStatusParams,
	getMovementFilePathParams,
	getMovementFilePathApi,
	setMovementMainPlanApi,
	setMovementMainPlanParams,
	updateMovementPlanApi,
	updateMovementPlanPayload,
	getMovementOtherExpenseApi,
	getMovementOtherExpenseParams,
	downloadLaytimeAttachApi,
	downloadLaytimeAttachParams,
	getMovementPlanDetailsApi,
	getMovementPlanDetailsParmas,
	downloadMovementPnlApi,
	downloadMovementPnlParams,
	getMovementTaskListParams,
	getMovementVoyageTaskParams,
	getMovementTaskCommentListParams,
	saveMovementTaskCommentParams,
	deleteMovementTaskCommentParams,
	getMovementTaskListApi,
	getMovementVoyageTaskApi,
	getMovemenTaskCommentListApi,
	saveMovementTaskCommentApi,
	deleteMovementTaskCommentApi,
	updateMovementTaskItemApi,
	updateMovementTaskItemParams,
	initMovementDetailsApi,
	initMovementDetailsParams,
	saveMovementTaskConfigParams,
	saveMovementTaskConfigApi,
	getMovementTctcApi,
	getMovementTctcParams
} from "@/api/voyageManage/movement";

export const getMovementGroupAction: ParameterType<getMovementGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteMovementAction: ParameterType<deleteMovementParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteMovementApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementAction: ParameterType<getMovementParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementCommentGroupAction: ParameterType<getMovementCommentGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementCommentGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementTaskGroupAction: ParameterType<getMovementTaskGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementTaskGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteMovementCommentAction: ParameterType<deleteMovementCommentParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteMovementCommentApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementCommentAction: ParameterType<saveMovementCommentParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementCommentApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const updateMovementTaskAction: ParameterType<updateMovementTaskParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	updateMovementTaskApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementOtherExpenseAction: ParameterType<getMovementOtherExpenseParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementOtherExpenseApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const updateMovementOtherExpensesAction: ParameterType<updateMovementOtherExpensesParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	updateMovementOtherExpensesApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementBunkerPlanAction: ParameterType<getMovementBunkerPlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementBunkerPlanApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementBunkerPlanAction: ParameterType<saveMovementBunkerPlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementBunkerPlanApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementStowagePlanActopn: ParameterType<getMovementStowagePlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementStowagePlanApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementStowagePlanAction: ParameterType<saveMovementStowagePlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementStowagePlanApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementLaytimeAction: ParameterType<getMovementLaytimeParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementLaytimeApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementLaytimeGroupAction: ParameterType<getMovementLaytimeGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementLaytimeGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementLaytimeGroupAction: ParameterType<saveMovementLaytimeGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementLaytimeGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteMovementLaytimeAction: ParameterType<deleteMovementLaytimeParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteMovementLaytimeApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementDetailAction: ParameterType<saveMovementDetailParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementDetailApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const editMovementVoyageStatusAction: ParameterType<editMovementVoyageStatusParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	editMovementVoyageStatusApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementFilePathAction: ParameterType<getMovementFilePathParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementFilePathApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const setMovementMainPlanAction: ParameterType<setMovementMainPlanParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	setMovementMainPlanApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const updateMovementPlanAction: ParameterType<updateMovementPlanPayload> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	updateMovementPlanApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const downloadLaytimeAttachAction: ParameterType<downloadLaytimeAttachParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	downloadLaytimeAttachApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementPlanDetailsAction: ParameterType<getMovementPlanDetailsParmas> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementPlanDetailsApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const downloadMovementPnlAction: ParameterType<downloadMovementPnlParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	downloadMovementPnlApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementTaskListAction: ParameterType<getMovementTaskListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementTaskListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementVoyageTaskAction: ParameterType<getMovementVoyageTaskParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementVoyageTaskApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementTaskCommentListAction: ParameterType<getMovementTaskCommentListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovemenTaskCommentListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementTaskCommentAction: ParameterType<saveMovementTaskCommentParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementTaskCommentApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteMovementTaskCommentAction: ParameterType<deleteMovementTaskCommentParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteMovementTaskCommentApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const updateMovementTaskItemAction: ParameterType<updateMovementTaskItemParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	updateMovementTaskItemApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const initMovementDetailsAction: ParameterType<initMovementDetailsParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	initMovementDetailsApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveMovementTaskConfigAction: ParameterType<saveMovementTaskConfigParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveMovementTaskConfigApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getMovementTctcAction: ParameterType<getMovementTctcParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getMovementTctcApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
